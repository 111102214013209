import ModalFooter from '@Components/ModalFooter';
import PageSkeletons from '@Components/PageSkeletons';
import SearchableSelect from '@Components/SearchableSelect';
import { useMacAdressRules } from '@Hooks/useMacAdressRules';
import { getAllAvailableBrands } from '@Store/Brand/action';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { getAllStoresOfBrands, resetAllStores } from '@Store/Store/action';
import { Brand } from '@Types/Brand';
import { AllReceiver } from '@Types/Receiver';
import { Checkbox, Form, Input, InputNumber, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onFinish?: (values: any) => void;
  receiverData?: AllReceiver;
};
export default function ReceiverForm({ onFinish, receiverData }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const macAdressRules = useMacAdressRules();
  const [form] = useForm();
  const brands = useAppSelector(s => s.Brand.allAvailableBrands);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const stores = useAppSelector(s => s.Store.allStores);

  const requiredRules = [{ required: true }];

  useEffect(() => {
    dispatch(getAllAvailableBrands());
  }, []);
  const handleBrandChange = (brandId: number) => {
    if (brandId) {
      setSelectedBrand(brands.data.find(b => b.Id === brandId));
    }
    dispatch(resetAllStores());
    form.setFieldsValue({ StoreId: undefined });
  };
  useEffect(() => {
    if (selectedBrand) {
      dispatch(getAllStoresOfBrands([selectedBrand.Id]));
    }
  }, [selectedBrand?.Id]);

  useEffect(() => {
    if (receiverData?.BrandId) {
      dispatch(getAllStoresOfBrands([receiverData.BrandId]));
    }
  }, [receiverData]);

  if (stores.status === 'Pending' && brands.status === 'Pending')
    return <PageSkeletons />;

  return (
    <Form
      form={form}
      name="user-add-form"
      id="user-add-form"
      onFinish={onFinish}
      className="user-add-container"
      labelCol={{ span: 6 }}
      labelWrap
      labelAlign="left"
      wrapperCol={{ span: 24 }}
      initialValues={receiverData}
    >
      <Form.Item label={t('name')} name="Name" rules={requiredRules}>
        <Input />
      </Form.Item>
      {!receiverData && (
        <>
          <Form.Item label={t('brand')} rules={requiredRules} name={'BrandId'}>
            <SearchableSelect
              placeholder={t('selectBrand')}
              allowClear
              showSearch
              onChange={handleBrandChange}
            >
              {brands.data.map(brand => (
                <Select.Option
                  key={brand.Id}
                  value={brand.Id}
                  label={brand.Name}
                >
                  {brand.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Form.Item label={t('store')} rules={requiredRules} name={'StoreId'}>
            <SearchableSelect
              placeholder={t('selectStore')}
              allowClear
              showSearch
            >
              {stores.data.map(store => (
                <Select.Option
                  key={store.Id}
                  value={store.Id}
                  label={store.Name}
                >
                  {store.Name}
                </Select.Option>
              ))}
            </SearchableSelect>
          </Form.Item>
          <Form.Item
            label={'Bt Mac Address'}
            name="BtMacAddress"
            rules={[...requiredRules, ...macAdressRules]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={requiredRules}
            label={t('externalId')}
            name="ExternalId"
          >
            <Input />
          </Form.Item>
        </>
      )}

      {receiverData && (
        <>
          <Form.Item
            label={t('scanThreshold')}
            name="ScanThreshold"
            rules={[{ required: true }]}
            tooltip={t('scanThresholdInfo')}
          >
            <InputNumber min={-99} max={-40} />
          </Form.Item>

          <Form.Item
            name="ShrinkDataFromLineEntrance"
            label={t('shrinkDataFromLineEntranceShort')}
            tooltip={t('shrinkDataFromLineEntrance')}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </>
      )}

      <ModalFooter formKey="user-add-form" />
    </Form>
  );
}
