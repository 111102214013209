import { createAction } from '@reduxjs/toolkit';
import {
  ON_SIDEBAR_HIDE_TOGGLE,
  ON_SIDEBAR_TOGGLE,
  SET_HEADER_TITLE,
  SET_HOMEPAGE_VIEW,
} from './type';
import { HomepageView } from '@Types/Dashboard';

export const onSidebarHiddenToggle = createAction(
  ON_SIDEBAR_HIDE_TOGGLE,
  (hidden: boolean) => {
    return {
      payload: hidden,
    };
  }
);

export const onSidebarToggle = createAction(
  ON_SIDEBAR_TOGGLE,
  (isCollapsed: boolean) => {
    return {
      payload: isCollapsed,
    };
  }
);

export const setHeaderTitle = createAction(
  SET_HEADER_TITLE,
  (title?: string | undefined) => {
    return {
      payload: title,
    };
  }
);

export const setHomepageView = createAction(
  SET_HOMEPAGE_VIEW,
  (value: HomepageView) => {
    return {
      payload: value,
    };
  }
);
