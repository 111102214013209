import { FetchedBrand } from '@Types/Brand';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { Tag } from 'antd';
import { getActiveBoardCount } from '@Utils/index';

interface BoardCountInfoProps
  extends Pick<
    FetchedBrand,
    | 'WaitingForCustomerCount'
    | 'OnCarrierCount'
    | 'BoardCount'
    | 'PassiveBoardCount'
    | 'NotInstalledBoardCount'
    | 'AtLeast3DaysPassiveBoardCount'
  > {}

export function BoardCountInfo(props: BoardCountInfoProps) {
  const {
    AtLeast3DaysPassiveBoardCount,
    BoardCount,
    NotInstalledBoardCount,
    PassiveBoardCount,
    OnCarrierCount,
    WaitingForCustomerCount,
  } = props;

  const activeBoardCount = getActiveBoardCount(props);

  const { t } = useTranslation();

  return (
    <div className="board-count-info">
      <div className="board-count-info-column">
        <Tag title={t('boardCount')}>{BoardCount}</Tag>
      </div>

      <div className="board-count-info-column">
        <Tag
          color="#78B3CE"
          title={t('waitingCustomer')}
          hidden={
            WaitingForCustomerCount === 0 ||
            WaitingForCustomerCount === undefined
          }
        >
          {WaitingForCustomerCount}
        </Tag>
        <Tag
          color="rgba(119, 21, 204, 0.51)"
          title={t('onTheWayBoards')}
          hidden={OnCarrierCount === 0 || OnCarrierCount === undefined}
        >
          {OnCarrierCount}
        </Tag>
      </div>

      <div className="board-count-info-column">
        <Tag
          color="#f48db4"
          title={t('offBoards')}
          hidden={PassiveBoardCount === 0}
        >
          {PassiveBoardCount}
        </Tag>

        <Tag
          color="#FCD47D"
          title={t('NotInstalledBoardCount')}
          hidden={NotInstalledBoardCount === 0}
          style={{ color: '#171717' }}
        >
          {NotInstalledBoardCount}
        </Tag>
      </div>

      <div className="board-count-info-column">
        <Tag
          color="#B03052"
          title={t('AtLeast3DaysPassiveBoardCount')}
          hidden={AtLeast3DaysPassiveBoardCount === 0}
        >
          {AtLeast3DaysPassiveBoardCount}
        </Tag>

        <Tag color="#7ED4AD" title={t('onBoards')} style={{ color: '#171717' }}>
          {activeBoardCount}
        </Tag>
      </div>
    </div>
  );
}
