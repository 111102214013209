import BrandCard from '@Cards/BrandCard';
import { BrandActionButtonEnums } from '@Cards/BrandCard/BrandActionButtons';
import { FirstItemText } from '@Components/Card/FirstItemText';
import CardGridWrapper from '@Components/CardGridWrapper';
import { useHomepageContext } from '@Features/homepage/hooks';
import { useAppSelector } from '@Store/hooks';
import { Button, Input, Row, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshButton } from '../RefreshButton';
import {
  BRAND_QUERY_PARAM,
  STORE_QUERY_PARAM,
} from '@Features/homepage/homepage.constants';
import { localeLowercase, scrollTo } from '@Utils/helpers';
import Status from '@Enums/Status';
import { checkHomepageTags } from '@Utils/index';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { OrderBy } from '@Enums/Filter';
import { FontColorsOutlined, NumberOutlined } from '@ant-design/icons';
import { Tooltip } from '@Components/Tooltip';
import { useUpdateUserConfig } from '@Hooks/useUpdateUserConfig';

export function BrandSelector() {
  const brands = useAppSelector(s => s.Brand.allBrands);

  const {
    tags,
    groupId,
    brandId,
    brandSearchValue,
    setBrandSearchValue,
    setStoreSearchValue,
    setBoardSearchValue,
    setCameraSearchValue,
    setBrandId,
    setStoreId,
    setQueryParam,
    deleteQueryParam,
    getBrands,
  } = useHomepageContext();

  const { t } = useTranslation();

  const selectedBrandRef = useRef<HTMLDivElement>(null);

  const userConfig = useUpdateUserConfig();

  useEffect(() => {
    getBrands();
  }, [userConfig.orderBy, userConfig.sortBy]);

  useEffect(() => {
    if (brands.status === Status.success) {
      scrollTo(selectedBrandRef);
    }
  }, [brands.status]);

  function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setBrandSearchValue(e.target.value);
    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  function brandChangeHandler(newBrandId: number) {
    const newValue = newBrandId === brandId ? undefined : newBrandId;
    setBrandId(newValue);
    setQueryParam(BRAND_QUERY_PARAM, newValue);

    setStoreId(undefined);
    deleteQueryParam(STORE_QUERY_PARAM);

    setBrandSearchValue('');
    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  const filteredBrands = brands?.data?.Data?.filter(brand => {
    if (groupId !== undefined && groupId !== brand.GroupId) return false;

    if (!checkHomepageTags(brand, tags)) return false;

    const brandNameCheck = localeLowercase(brand.Name).includes(
      localeLowercase(brandSearchValue)
    );

    const brandIdCheck = brand.Id.toString().includes(
      localeLowercase(brandSearchValue)
    );

    const accountManagerCheck = brand.AccountManager?.startsWith(
      localeLowercase(brandSearchValue)
    );

    const operationManagerCheck = brand.OperationManager?.startsWith(
      localeLowercase(brandSearchValue)
    );

    const isPocCheck =
      localeLowercase(brandSearchValue) === 'poc' && brand.InPOC;

    return (
      brandNameCheck ||
      brandIdCheck ||
      accountManagerCheck ||
      operationManagerCheck ||
      isPocCheck
    );
  });

  const selectedBrand = brands?.data?.Data?.find(brand => brandId === brand.Id);

  return (
    <div className="homepage-entity-selector-container">
      <Row justify="space-between" align="middle" wrap>
        <Title level={5} className="selector-title">
          {t('brand')}
        </Title>
        {selectedBrand && (
          <Tag color="magenta" className="selector-tag">
            {selectedBrand.Name}
          </Tag>
        )}
      </Row>

      <div className="store-selector-row">
        <Input
          onChange={searchChangeHandler}
          value={brandSearchValue}
          placeholder={t('searchBrandNameAndIdPlaceholder')}
        />
        <SortByButton />
        <OrderByButton />
        <RefreshButton onClick={getBrands} buttonProps={{ size: 'middle' }} />
      </div>

      <CardGridWrapper
        cards={filteredBrands?.map(brand => (
          <div
            ref={brand.Id === selectedBrand?.Id ? selectedBrandRef : undefined}
            key={brand.Id}
          >
            <BrandCard
              brand={brand}
              onClick={() => brandChangeHandler(brand.Id)}
              firstItem={<FirstItemText text={brand.Name + ' - ' + brand.Id} />}
              isSelected={selectedBrand?.Id === brand.Id}
              hiddenButtons={[BrandActionButtonEnums.ALL]}
            />
          </div>
        ))}
        noData={filteredBrands?.length === 0}
        status={brands.status}
        error={brands.ErrorMessage}
        containerClassName="brand-selector-container homepage-entity-selector"
      />
    </div>
  );
}

function SortByButton() {
  const { update, sortBy } = useUpdateUserConfig();

  function handleChange(value: 'name' | 'id') {
    return () => {
      update({ homepageBrandSortBy: value });
    };
  }

  const { t } = useTranslation();

  return (
    <div>
      <Tooltip title={t(sortBy === 'id' ? 'sortById' : 'sortByName')}>
        {sortBy === 'id' ? (
          <Button onClick={handleChange('name')} icon={<NumberOutlined />} />
        ) : (
          <Button onClick={handleChange('id')} icon={<FontColorsOutlined />} />
        )}
      </Tooltip>
    </div>
  );
}

function OrderByButton() {
  const { update, orderBy } = useUpdateUserConfig();

  return (
    <PaginationOrderByFilter
      defaultValue={orderBy === 'asc' ? OrderBy.ASC : OrderBy.DESC}
      onChange={v =>
        update({ homepageBrandOrderBy: v === OrderBy.ASC ? 'asc' : 'desc' })
      }
    />
  );
}
