import { Space, Typography } from 'antd';

interface BoardJiraLinksProps {
  links?: string[];
}

export function BoardJiraLinks(props: BoardJiraLinksProps) {
  const { links = [] } = props;

  function getIssueName(link: string) {
    try {
      const splittedLink = link.split('/');
      return splittedLink[splittedLink.length - 1];
    } catch (e) {
      console.error(e);
      return link;
    }
  }

  if (links.length === 0) {
    return <>-</>;
  }

  return (
    <Space wrap size={10}>
      {links.map(link => (
        <Typography.Link key={link} target="_blank" href={link}>
          {getIssueName(link)}
        </Typography.Link>
      ))}
    </Space>
  );
}
