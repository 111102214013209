import Status from '@Enums/Status';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { AllFetchedBoards, FetchedBoard } from '@Types/Board';
import FilledText from '@Components/FilledText';
import BoardActionButtons from '@Cards/BoardCard/BoardActionButtons';
import useWindowSize from '@Hooks/useWindowSize';
import { BoardStatusInstalledIndicator } from '@Components/BoardStatusInstalledIndicator';
import { formatBoardIpText } from '@Features/boards/utils/board-utils';
import { BoardJiraLinks } from '@Features/boards/components';

interface Params {
  boards: AllFetchedBoards;
  isArchived: boolean;
  getBoards: () => void;
}

/**
 *
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <BoardsDetail />.
 * @returns
 */
export default function useBoardsColumns(
  params: Params
): ColumnsType<FetchedBoard> {
  const { boards } = params;
  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BOARD_ACTION);
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const boardsTableColumns: ColumnsType<FetchedBoard> = useMemo(() => {
    if (boards.status !== Status.success) return [];

    const columns: ColumnsType<FetchedBoard> = [
      {
        title: t('name'),
        dataIndex: 'Name',
      },
      {
        title: <BoardStatusInstalledIndicator.Label />,
        dataIndex: 'Status',
        render: (_, board: FetchedBoard) => (
          <BoardStatusInstalledIndicator
            board={board}
            onRefetch={params.getBoards}
          />
        ),
      },
      {
        title: t('entityIds'),
        dataIndex: 'entityIds',
        render: (_: any, board: FetchedBoard) => (
          <HierarchyItems
            brandId={board.BrandId}
            storeId={board.StoreId}
            boardId={board.Id}
            brand={board.Brand}
            store={board.Store}
            board={board.Name}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 300 : 160,
      },
      {
        title: t('codeVersion'),
        dataIndex: 'CodeVersion',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('IP'),
        dataIndex: 'IP',
        render: (value: string, board) => (
          <FilledText
            text={formatBoardIpText(board)}
            copyable={value ? { text: value } : undefined}
          />
        ),
      },
      {
        title: t('Wifi Mac'),
        dataIndex: 'WifiMac',
        render: (value: string) => (
          <FilledText text={value} copyable={!!value} />
        ),
      },
      {
        title: t('ETH Mac'),
        dataIndex: 'ETHMac',
        render: (value: string) => (
          <FilledText text={value} copyable={!!value} />
        ),
      },
      {
        title: t('Kernel Version'),
        dataIndex: 'KernelVersion',
        render: (value: string) => (
          <FilledText text={value} copyable={!!value} />
        ),
        width: 150,
      },

      {
        title: t('lastStatusChangeTime'),
        dataIndex: 'LastStatusChangeTime',
        render: (value: string) => <FilledText text={value} />,
      },

      {
        title: 'Jira',
        dataIndex: 'BoardJiraKeys',
        render: (_, record) => <BoardJiraLinks links={record.BoardJiraKeys} />,
      },
    ];

    if (boardActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, board: FetchedBoard) => (
          <BoardActionButtons {...params} board={board} />
        ),
      });
    }

    return columns;
  }, [boards.status, windowSize.width]);

  return boardsTableColumns;
}
