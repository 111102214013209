import StatusBadge from '@Components/StatusBadge';
import { FetchedBoard } from '@Types/Board';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import './style.scss';
import { Divider, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import CardButton from '@Components/CardButton';
import { apiCall } from '@Utils/index';
import BoardsService from '@Services/Api/Boards';
import { Tooltip } from '@Components/Tooltip';
import { InstallationStatus } from '@Enums/Board';

interface IProps {
  onRefetch: () => void;
  board: FetchedBoard;
}

export function BoardStatusInstalledIndicator(props: IProps) {
  const { board, onRefetch } = props;
  const { t } = useTranslation();

  const setInstalledStatus = (status: InstallationStatus) => async () => {
    await apiCall(() =>
      new BoardsService().SetInstalledStatus(board.Id, status)
    );
    onRefetch();
  };

  const statuses = {
    [InstallationStatus.Installed]: {
      tooltip: 'hadInstalled',
      icon: (
        <CheckCircleOutlined style={{ color: '#28B463', fontSize: '23px' }} />
      ),
      onClickStatus: InstallationStatus.NotInstalled,
      switchStatusTooltip: 'setAsUninstalled',
    },
    [InstallationStatus.NotInstalled]: {
      tooltip: 'notHadInstalled',
      icon: (
        <CloseCircleOutlined style={{ color: '#909497', fontSize: '23px' }} />
      ),
      onClickStatus: InstallationStatus.WaitingCustomer,
      switchStatusTooltip: 'setAsWaitingCustomer',
    },
    [InstallationStatus.WaitingCustomer]: {
      tooltip: 'waitingCustomer',
      icon: (
        <ClockCircleOutlined style={{ color: '#78B3CE', fontSize: '23px' }} />
      ),
      onClickStatus: InstallationStatus.OnTheWay,
      switchStatusTooltip: 'setAsOnTheWay',
    },
    [InstallationStatus.OnTheWay]: {
      tooltip: 'onTheWay',
      icon: <ClockCircleOutlined style={{ color: '#44f', fontSize: '23px' }} />,
      onClickStatus: InstallationStatus.Installed,
      switchStatusTooltip: 'setAsInstalled',
    },
  };

  const content =
    statuses[board.InstallationStatus ?? InstallationStatus.NotInstalled];

  return (
    <div className="board-status-installed-indicator">
      <StatusBadge isOn={board.Status} />
      <Divider type="vertical" style={{ marginInline: 4 }} />
      <Space size={2} align="center">
        <Tooltip title={t(content.tooltip)}>{content.icon}</Tooltip>
        <CardButton
          onClick={setInstalledStatus(content.onClickStatus)}
          icon={<ReloadOutlined />}
          tooltipKey={content.switchStatusTooltip}
        />
      </Space>
    </div>
  );
}

function Label() {
  const { t } = useTranslation();
  return (
    <div>
      {t('status')}
      <Divider type="vertical" style={{ marginInline: 4 }} />
      {t('installed')}
    </div>
  );
}

BoardStatusInstalledIndicator.Label = Label;
