import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import { PageLog } from '@Types/PageLogs';
import { Space, Typography } from 'antd';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { PageLogSourceTag } from '@Pages/PageLogs/Components/PageLogSourceTag';

const { Text, Title } = Typography;

type Props = {
  pageLog: PageLog;
};

export default function PageLogCard({
  pageLog: {
    Brand,
    BrandId,
    CreatedDate,
    Seconds,
    Store,
    StoreId,
    Url,
    UserName,
    Source,
  },
}: Props) {
  const { t } = useTranslation();

  return (
    <Card
      className="page-log-card"
      isDetailAlwaysOpen
      content={{
        firsItem: {
          custom: (
            <Space direction="vertical" size="small" wrap>
              <Title level={5} title={UserName}>
                {UserName}
              </Title>
              <Text italic title={Url}>
                {Url}
              </Text>
            </Space>
          ),
        },
        secondItem: {
          custom: (
            <HierarchyItems
              brandId={BrandId}
              brand={Brand}
              storeId={StoreId}
              store={Store}
            />
          ),
        },
      }}
      details={[
        { key: { text: t('createdDate') }, value: { text: CreatedDate } },
        { key: { text: t('seconds') }, value: { text: Seconds.toFixed(2) } },
        {
          key: { text: t('channel') },
          value: { isCustom: true, text: <PageLogSourceTag source={Source} /> },
        },
      ]}
    />
  );
}
