import { createReducer } from '@reduxjs/toolkit';
import {
  getAllStoresOfBrands,
  getArchivedStores,
  getStoreDetail,
  getUnarchivedStores,
  setSelectedStore,
  setSelectedStores,
  resetAllStores,
  getAllUnarchivedStores,
  getAllArchivedStores,
  resetCurrentStore,
  getStoresCameraStatus,
  getMultipleStoresCameraStatus,
  getStoreWifiConfigiration,
  getUnArchiveStoreCameras,
  resetStoreWifiConfig,
  getStoreCheckins,
  getStoresGeoCoords,
  getCurrencies,
} from './action';
import { asyncFetchInterface } from '../../Types/ReduxTypes';
import {
  AllFetchedStores,
  AllGeoCoordStores,
  CheckInOuts,
  CheckInOutsResponse,
  Currency,
  FetchedStoresResponse,
  GeoCoordStoresResponse,
  Store,
} from '@Types/Store';
import { CameraStatuses } from '@Types/Camera';
import { WifiConfigirationData } from '@Types/WifiConfigiration';
import { UnArchiveBrandCameras } from '@Types/Brand';
import Status from '@Enums/Status';
interface stateInterface {
  allStores: asyncFetchInterface<Store[]>;
  fetchedStores: AllFetchedStores;
  selectedStore: Store;
  selectedStores: Store[];
  currentStore: asyncFetchInterface<Store>;
  storesCameraStatus: asyncFetchInterface<CameraStatuses>;
  wifiConfStore: asyncFetchInterface<WifiConfigirationData>;
  unArchiveStoreCameras: asyncFetchInterface<UnArchiveBrandCameras[]>;
  checkins: CheckInOuts;
  geoCoordStores: AllGeoCoordStores;
  currencies: asyncFetchInterface<Currency[]>;
}

const initialState: stateInterface = {
  currencies: {
    data: [],
    status: Status.empty,
  },
  allStores: {
    data: [],
    status: 'Empty',
  },
  fetchedStores: {
    data: {} as FetchedStoresResponse,
    status: 'Empty',
  },
  selectedStore: {} as Store,
  selectedStores: [] as Store[],
  currentStore: {
    data: {} as Store,
    status: 'Empty',
  },
  storesCameraStatus: {
    data: [],
    status: 'Empty',
  },
  wifiConfStore: { data: {} as WifiConfigirationData, status: 'Empty' },
  unArchiveStoreCameras: { data: [], status: Status.empty },
  checkins: { data: {} as CheckInOutsResponse, status: Status.empty },
  geoCoordStores: {
    data: {} as GeoCoordStoresResponse,
    status: Status.empty,
  },
};

const StoreReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAllStoresOfBrands.pending, state => {
      state.allStores = {
        ...state.allStores,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllStoresOfBrands.fulfilled, (state, action) => {
      state.allStores.data = action.payload;
      state.allStores.status = 'fulfilled';
    })
    .addCase(getAllStoresOfBrands.rejected, (state, action) => {
      state.allStores = {
        ...state.allStores,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getUnarchivedStores.pending, state => {
      state.fetchedStores = {
        ...state.fetchedStores,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnarchivedStores.fulfilled, (state, action) => {
      state.fetchedStores.data = action.payload as FetchedStoresResponse;
      state.fetchedStores.status = 'fulfilled';
    })
    .addCase(getUnarchivedStores.rejected, (state, action) => {
      state.fetchedStores = {
        ...state.fetchedStores,
        data: {} as FetchedStoresResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoresGeoCoords.pending, state => {
      state.geoCoordStores = {
        ...state.geoCoordStores,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoresGeoCoords.fulfilled, (state, action) => {
      state.geoCoordStores.data = action.payload as GeoCoordStoresResponse;
      state.geoCoordStores.status = 'fulfilled';
    })
    .addCase(getStoresGeoCoords.rejected, (state, action) => {
      state.geoCoordStores = {
        ...state.geoCoordStores,
        data: {} as GeoCoordStoresResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getArchivedStores.pending, state => {
      state.fetchedStores = {
        ...state.fetchedStores,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getArchivedStores.fulfilled, (state, action) => {
      state.fetchedStores.data = action.payload as FetchedStoresResponse;
      state.fetchedStores.status = 'fulfilled';
    })
    .addCase(getArchivedStores.rejected, (state, action) => {
      state.fetchedStores = {
        ...state.fetchedStores,
        data: {} as FetchedStoresResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoreDetail.pending, state => {
      state.currentStore = {
        ...state.currentStore,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreDetail.fulfilled, (state, action) => {
      state.currentStore.data = action.payload as Store;
      state.currentStore.status = 'fulfilled';
    })
    .addCase(getStoreDetail.rejected, (state, action) => {
      state.currentStore = {
        ...state.currentStore,
        data: {} as Store,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(setSelectedStore, (state, action) => {
      state.selectedStore = action.payload;
    })
    .addCase(setSelectedStores, (state, action) => {
      state.selectedStores = action.payload;
    })
    .addCase(resetAllStores, (state, action) => {
      state.allStores = {
        data: [],
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedStores.pending, state => {
      state.fetchedStores = {
        ...state.fetchedStores,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllUnarchivedStores.fulfilled, (state, action) => {
      state.fetchedStores.data = action.payload as FetchedStoresResponse;
      state.fetchedStores.status = 'fulfilled';
    })
    .addCase(getAllUnarchivedStores.rejected, (state, action) => {
      state.fetchedStores = {
        ...state.fetchedStores,
        data: {} as FetchedStoresResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getAllArchivedStores.pending, state => {
      state.fetchedStores = {
        ...state.fetchedStores,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getAllArchivedStores.fulfilled, (state, action) => {
      state.fetchedStores.data = action.payload as FetchedStoresResponse;
      state.fetchedStores.status = 'fulfilled';
    })
    .addCase(getAllArchivedStores.rejected, (state, action) => {
      state.fetchedStores = {
        ...state.fetchedStores,
        data: {} as FetchedStoresResponse,
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetCurrentStore, state => {
      state.currentStore = {
        ...state.currentStore,
        data: {} as Store,
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoresCameraStatus.pending, state => {
      state.storesCameraStatus = {
        ...state.storesCameraStatus,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoresCameraStatus.fulfilled, (state, action) => {
      state.storesCameraStatus.data = action.payload;
      state.storesCameraStatus.status = 'fulfilled';
    })
    .addCase(getStoresCameraStatus.rejected, (state, action) => {
      state.storesCameraStatus = {
        ...state.storesCameraStatus,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getMultipleStoresCameraStatus.pending, state => {
      state.storesCameraStatus = {
        ...state.storesCameraStatus,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getMultipleStoresCameraStatus.fulfilled, (state, action) => {
      state.storesCameraStatus.data = action.payload;
      state.storesCameraStatus.status = 'fulfilled';
    })
    .addCase(getMultipleStoresCameraStatus.rejected, (state, action) => {
      state.storesCameraStatus = {
        ...state.storesCameraStatus,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoreWifiConfigiration.pending, state => {
      state.wifiConfStore = {
        ...state.wifiConfStore,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreWifiConfigiration.fulfilled, (state, action) => {
      state.wifiConfStore.data = action.payload as WifiConfigirationData;
      state.wifiConfStore.status = 'fulfilled';
    })
    .addCase(getStoreWifiConfigiration.rejected, (state, action) => {
      state.wifiConfStore = {
        ...state.wifiConfStore,
        data: {},
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(resetStoreWifiConfig, state => {
      state.wifiConfStore = {
        ...state.wifiConfStore,
        data: {},
        status: 'Empty',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnArchiveStoreCameras.pending, state => {
      state.unArchiveStoreCameras = {
        ...state.unArchiveStoreCameras,
        status: 'Pending',
        ErrorMessage: undefined,
      };
    })
    .addCase(getUnArchiveStoreCameras.fulfilled, (state, action) => {
      state.unArchiveStoreCameras.data =
        action.payload as UnArchiveBrandCameras[];
      state.unArchiveStoreCameras.status = 'fulfilled';
    })
    .addCase(getUnArchiveStoreCameras.rejected, (state, action) => {
      state.unArchiveStoreCameras = {
        ...state.unArchiveStoreCameras,
        data: [],
        status: 'Rejected',
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getStoreCheckins.pending, state => {
      state.checkins = {
        ...state.checkins,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getStoreCheckins.fulfilled, (state, action) => {
      state.checkins.data = action.payload as CheckInOutsResponse;
      state.checkins.status = Status.success;
    })
    .addCase(getStoreCheckins.rejected, (state, action) => {
      state.checkins = {
        ...state.checkins,
        data: {} as CheckInOutsResponse,
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    })
    .addCase(getCurrencies.pending, state => {
      state.currencies = {
        ...state.currencies,
        status: Status.pending,
        ErrorMessage: undefined,
      };
    })
    .addCase(getCurrencies.fulfilled, (state, action) => {
      state.currencies.data = action.payload;
      state.currencies.status = Status.success;
    })
    .addCase(getCurrencies.rejected, (state, action) => {
      state.currencies = {
        ...state.currencies,
        data: [],
        status: Status.error,
        ErrorMessage: action.error.message,
      };
    });
});

export default StoreReducer;
