import { serialized } from '@Types/Coordinates';
import StackBarOptions from '../Options/StackBarOptions';
import { ReactElement } from 'react';
import { Chart } from 'react-chartjs-2';

type Props = {
  data: serialized;
  Label?: serialized;
  lineData?: serialized;
  lineLabel?: string;
  barLabel?: string;
  extraLines?: {
    label: string;
    color: string;
    data: number[];
    yAxisID: string;
  }[];
};

function ComposedChart(props: Props): ReactElement {
  const { data, Label, lineData, lineLabel, barLabel, extraLines = [] } = props;

  const dataSets = [
    {
      type: 'line' as const,
      label: lineLabel,
      backgroundColor: '#26b99a',
      borderColor: '#26b99a',
      borderWidth: 0.5,
      fill: false,
      data: lineData?.Serial,
      yAxisID: 'y1',
    },
    {
      type: 'bar' as const,
      label: barLabel,
      data: data.Serial,
      backgroundColor: '#196ebd',
      yAxisID: 'y',
    },
    ...extraLines.map(line => ({
      type: 'line' as const,
      label: line.label,
      backgroundColor: line.color,
      borderColor: line.color,
      borderWidth: 0.5,
      fill: false,
      data: line.data,
      yAxisID: 'y2',
    })),
  ];
  const convert = {
    labels: Label?.Serial,
    datasets: [...dataSets],
  };
  return <Chart type="bar" data={convert} options={StackBarOptions} />;
}
export default ComposedChart;
