import SelectedFilters, { IDataForm } from './SelectedFilters';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.scss';
import CoordinatesTypes, {
  ConvertCameraCoordinate,
  MultipleCoordinatesFilterParams,
} from '@Types/Coordinates';
import CardGridWrapper from '@Components/CardGridWrapper';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import {
  getStoresLineCoordinate,
  getStoresCameraCoordinate,
  resetStoreCameraCoordinate,
  resetStoreLineCoordinate,
  getMultipleStoresCameraCoordinate,
  getMultipleStoresLineCoordinate,
} from '@Store/Coordinates/action';
import CoordinateCard from '@Cards/CoordinateCard';
import { useParams } from 'react-router-dom';
import {
  getMultipleStoresCameraStatus,
  getStoreDetail,
  getStoresCameraStatus,
  resetCurrentStore,
} from '@Store/Store/action';
import { convertStoreIdsToQueryParam } from '@Utils/Api';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { CameraClosed } from '@Enums/Camera';
import { convertApiDate } from '@Utils/index';
import moment from 'moment';

function Data() {
  const [CameraCoordinateData, setCameraCoordinateData] =
    useState<ConvertCameraCoordinate>();

  const [selectedPeriod, setSelectedPeriod] = useState<'hourly' | 'daily'>(
    'hourly'
  );

  const currentStore = useAppSelector(s => s.Store.currentStore.data);
  const { storeCameraCoordinate, storeLineCoordinate } = useAppSelector(
    s => s.Coordinate
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { storeId } = useParams();
  const storesCameraStatus = useAppSelector(s => s.Store.storesCameraStatus);

  useSetHeaderTitle(
    storeId && currentStore
      ? currentStore.Brand + ' -> ' + currentStore.Name
      : t('cameraDatas')
  );

  useEffect(() => {
    if (storeId) {
      dispatch(getStoreDetail(storeId!));

      let defaultParam: CoordinatesTypes.CoordinatesFilterParams = {
        storeId: parseInt(storeId),
        type: 0,
        filterNumber: 48,
        period: 0,
      };
      let lineDefaultParam: CoordinatesTypes.CoordinatesFilterParams = {
        storeId: parseInt(storeId),
        type: 1,
        filterNumber: 48,
        period: 0,
      };

      dispatch(getStoresCameraCoordinate(defaultParam));
      dispatch(getStoresLineCoordinate(lineDefaultParam));
      dispatch(getStoresCameraStatus(defaultParam));
      return () => {
        dispatch(resetCurrentStore());
      };
    } else {
      dispatch(resetStoreCameraCoordinate());
      dispatch(resetStoreLineCoordinate());
    }
  }, [storeId]);

  useEffect(() => {
    let regexFindNumber = /\((.*?)\)/g;
    if (storeCameraCoordinate.data.length > 0) {
      var convertStoreCameraCoordinateData = storeCameraCoordinate.data.map(
        cam => {
          const camId =
            cam.Name !== 'Labels'
              ? cam.Name.match(regexFindNumber)?.[0].replace(/[^\w\s]/gi, '')
              : 'Labels';
          return {
            ...cam,
            CameraId: parseInt(camId!),
          };
        }
      );
      setCameraCoordinateData(convertStoreCameraCoordinateData);
    }
  }, [storeCameraCoordinate.data]);

  const onFinish = async ({
    storeIds,
    brandId,
    period,
    filterNumber,
  }: IDataForm) => {
    setSelectedPeriod(period === 0 ? 'hourly' : 'daily');

    const paramBase: Omit<MultipleCoordinatesFilterParams, 'type'> = {
      brandId: brandId ?? currentStore.BrandId,
      storeids: convertStoreIdsToQueryParam(storeIds ?? [parseInt(storeId!)]),
      filterNumber: filterNumber || 48,
      period: period || 0,
    };

    const param = { ...paramBase, type: 0 };
    const lineParam = { ...paramBase, type: 1 };

    dispatch(getMultipleStoresCameraCoordinate(param));
    dispatch(getMultipleStoresLineCoordinate(lineParam));
    dispatch(getMultipleStoresCameraStatus(param));
  };

  function formatDate(dateValue: string, hasDot: boolean) {
    const date = moment(dateValue);

    let outputFormat = selectedPeriod === 'daily' ? 'DD/MM/YYYY' : 'DD/MM HH';

    if (hasDot) {
      outputFormat = outputFormat.replace(/\//g, '.');
    }

    let formattedLabel = date
      .add(new Date().getTimezoneOffset(), 'minutes')
      .format(outputFormat);

    if (selectedPeriod === 'hourly') {
      formattedLabel = formattedLabel + ':00';
    }

    return formattedLabel;
  }

  function getNumberOfShutdownLine(
    statuses: {
      Date: string;
      Closed: CameraClosed;
    }[]
  ): {
    label: string;
    color: string;
    data: number[];
    yAxisID: string;
  } {
    const labels =
      (storeCameraCoordinate.data.find(i => i.Name === 'Labels')?.Serial as
        | string[]
        | undefined) ?? [];

    const groups: Record<string, number> = {};

    for (const status of statuses) {
      if (status.Closed === CameraClosed.closed) {
        const formattedLabel = formatDate(
          status.Date,
          labels.some(label => label.includes('.'))
        );

        groups[formattedLabel] = (groups[formattedLabel] ?? 0) + 1;
      }
    }

    return {
      color: '#f91e1d',
      data: labels.map(label => groups[label] ?? 0),
      label: t('numberOfShutdowns'),
      yAxisID: 'y2',
    };
  }

  return (
    <Card className="Data">
      <div className="grid-content-wrapper">
        <div className="filter-row selectedFilters">
          <SelectedFilters onFinish={onFinish} />
        </div>
      </div>
      <div className="card-grid-container">
        <CardGridWrapper
          status={storeCameraCoordinate.status}
          noData={CameraCoordinateData?.length === 0}
          cards={CameraCoordinateData?.map((data, i) => (
            <div key={data.Name}>
              <CoordinateCard
                data={data}
                Label={storeCameraCoordinate.data.find(
                  i => i.Name === 'Labels'
                )}
                lineData={storeLineCoordinate.data[i]}
                lineLabel={t('line.Data')}
                barLabel={t('coordinate.Data')}
                timeLineCardData={storesCameraStatus?.data.find(
                  cam => cam.CameraId === data.CameraId
                )}
                status={storeCameraCoordinate.status}
                extraLines={[
                  getNumberOfShutdownLine(
                    storesCameraStatus?.data.find(
                      cam => cam.CameraId === data.CameraId
                    )?.Status ?? []
                  ),
                ]}
              />
            </div>
          ))}
        />
      </div>
    </Card>
  );
}

export default Data;
