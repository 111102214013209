import { createReducer } from '@reduxjs/toolkit';
import {
  onSidebarHiddenToggle,
  onSidebarToggle,
  setHeaderTitle,
  setHomepageView,
} from './action';
import { HomepageView } from '@Types/Dashboard';

interface initialStateInterface {
  isSidebarHidden: boolean;
  isSidebarCollapsed: boolean;
  headerTitle: string | undefined;
  homepageView: HomepageView;
}

const initialState: initialStateInterface = {
  isSidebarHidden: false,
  isSidebarCollapsed: false,
  headerTitle: undefined,
  homepageView: 'list',
};

const DashboardReducer = createReducer(initialState, builder => {
  builder
    .addCase(onSidebarHiddenToggle, (state, action) => {
      state.isSidebarHidden = action.payload;
    })
    .addCase(onSidebarToggle, (state, action) => {
      state.isSidebarCollapsed = action.payload;
    })
    .addCase(setHeaderTitle, (state, action) => {
      state.headerTitle = action.payload;
    })
    .addCase(setHomepageView, (state, action) => {
      state.homepageView = action.payload;
    });
});

export default DashboardReducer;
