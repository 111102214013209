import FormCard from '@Components/FormCard';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { EditedReceiver } from '@Types/Receiver';
import PageSkeletons from '@Components/PageSkeletons';
import { getReceiverById } from '@Store/Receiver/action';
import ReceiversService from '@Services/Api/Receivers';
import ReceiverForm from '@Forms/ReceiverForm';
import { apiCall } from '@Utils';

type Props = {};

export default function ReceiverEdit({}: Props) {
  const { receiverId } = useParams();
  const dispatch = useAppDispatch();
  const receiverData = useAppSelector(s => s.Receiver.receiverData);
  let navigate = useNavigate();

  useEffect(() => {
    if (receiverId) {
      dispatch(getReceiverById(parseInt(receiverId)));
    }
  }, [receiverId]);

  const handleSubmit = async (newReceiver: EditedReceiver) => {
    let editedReceiverData: EditedReceiver = {
      Name: newReceiver.Name,
      StoreId: receiverData.data?.StoreId,
      BtMacAddress: receiverData.data?.BTMacAddress,
      ExternalId: receiverData.data?.ExternalId,
      ScanThreshold: newReceiver.ScanThreshold,
      ShrinkDataFromLineEntrance: newReceiver.ShrinkDataFromLineEntrance
        ? 1
        : 0,
    };
    try {
      await apiCall(() => editReceiver(editedReceiverData), 'editReceiver');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editReceiver = async (newReceiver: EditedReceiver) => {
    return await new ReceiversService().EditReceiver(
      parseInt(receiverId!),
      newReceiver
    );
  };

  if (
    Object.keys(receiverData).length === 0 ||
    receiverData.status === 'Pending'
  )
    return <PageSkeletons />;
  return (
    <FormCard titleIcon={<EditOutlined />} titleKey="editReceiver">
      <ReceiverForm onFinish={handleSubmit} receiverData={receiverData.data} />
    </FormCard>
  );
}
