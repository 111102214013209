import { ChartOptions } from 'chart.js';

const StackBarOptions: ChartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        // drawBorder: false,
      },
    },
    y: {
      grid: {
        display: false,
        // drawBorder: false,
      },
      stacked: true,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
    y2: {
      type: 'linear' as const,
      display: false,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    tooltip: {
      intersect: false,
      mode: 'index',
      backgroundColor: '#7a7a7a',
      yAlign: 'bottom',
      xAlign: 'center',
      padding: {
        left: 5,
        right: 5,
        top: 4,
        bottom: 4,
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        boxHeight: 8,
        boxWidth: 14,
      },
    },
  },
};

export default StackBarOptions;
