import { EditOutlined } from '@ant-design/icons';
import FormCard from '@Components/FormCard';
import { Spin } from 'antd';
import { EditedBoard } from '@Types/Board';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect } from 'react';
import { getBoardDetail } from '@Store/Board/action';
import BoardsService from '@Services/Api/Boards';
import BoardForm from '@Forms/BoardForm';
import { apiCall } from '@Utils/index';

const EditBoard = () => {
  const board = useAppSelector(s => s.Board.currentBoard.data);

  const { boardId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (boardId) {
      dispatch(getBoardDetail(parseInt(boardId)));
    }
  }, [boardId]);

  let navigate = useNavigate();

  const handleSubmit = async ({
    ReceiverShrinkDataFromLineEntrance,
    ...editedBoard
  }: EditedBoard) => {
    try {
      await apiCall(() => editBoard(editedBoard), 'defaultProcess');
      navigate(-1);
    } catch (e) {
      console.warn(e);
    }
  };

  const editBoard = async (editedBoard: EditedBoard) => {
    return await new BoardsService().EditBoard(parseInt(boardId!), editedBoard);
  };

  if (!board?.Id) return <Spin />;
  return (
    <FormCard
      titleIcon={<EditOutlined />}
      titleKey="editBoard"
      className="board-detail-wrapper"
    >
      <BoardForm onFinish={handleSubmit} board={board} />
    </FormCard>
  );
};

export default EditBoard;
