import StoreCard from '@Cards/StoreCard';
import CardGridWrapper from '@Components/CardGridWrapper';
import { useHomepageContext } from '@Features/homepage/hooks';
import { useAppSelector } from '@Store/hooks';
import Title from 'antd/lib/typography/Title';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ArchivedFilter from '@Components/ArchivedFilter';
import { Divider, Input, Row, Tag } from 'antd';
import { FirstItemText } from '@Components/Card/FirstItemText';
import { StoreActionButtonEnums } from '@Cards/StoreCard/StoreActionButtons';
import { RefreshButton } from '../RefreshButton';
import { STORE_QUERY_PARAM } from '@Features/homepage/homepage.constants';
import Status from '@Enums/Status';
import { localeLowercase, scrollTo } from '@Utils/helpers';
import { checkHomepageTags } from '@Utils/index';

export function StoreSelector() {
  const stores = useAppSelector(s => s.Store.fetchedStores);

  const {
    tags,
    storeSearchValue,
    brandId,
    storeId,
    setStoreSearchValue,
    setBoardSearchValue,
    setCameraSearchValue,
    setStoreId,
    getStores,
    setQueryParam,
    showArchivedStores,
  } = useHomepageContext();

  const { t } = useTranslation();

  const selectedStoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (brandId) {
      getStoresRequest();
    }
  }, [brandId, showArchivedStores.value]);

  useEffect(() => {
    if (stores.status === Status.success) {
      scrollTo(selectedStoreRef);
    }
  }, [stores.status]);

  function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setStoreSearchValue(e.target.value);
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  function storeChangeHandler(newStoreId: number) {
    setStoreId(newStoreId);
    setQueryParam(STORE_QUERY_PARAM, newStoreId);

    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  function getStoresRequest() {
    getStores(showArchivedStores.value);
  }

  const filteredStores = stores?.data?.Data?.filter(store => {
    if (!checkHomepageTags(store, tags)) return false;

    const storeNameCheck = localeLowercase(store.StoreName).includes(
      localeLowercase(storeSearchValue)
    );

    const storeIdCheck = store.StoreId.toString().includes(storeSearchValue);

    const cityCheck = store.CityName
      ? localeLowercase(store.CityName).startsWith(
          localeLowercase(storeSearchValue)
        )
      : undefined;

    const countryCheck = store.CountryName
      ? localeLowercase(store.CountryName).startsWith(
          localeLowercase(storeSearchValue)
        )
      : undefined;

    return storeNameCheck || storeIdCheck || cityCheck || countryCheck;
  });

  const selectedStore = stores?.data?.Data?.find(
    store => storeId === store.StoreId
  );

  if (!brandId) {
    return <></>;
  }

  return (
    <>
      <Divider type="vertical" className="homepage-divider" />
      <div className="store-selector-container homepage-entity-selector-container">
        <Row
          justify="space-between"
          align="middle"
          wrap
          className="selector-header-row"
        >
          <Title level={5} className="selector-title">
            {t('store')}
          </Title>
          {selectedStore && (
            <Tag color="volcano" className="selector-tag">
              {selectedStore.StoreName}
            </Tag>
          )}
        </Row>
        <div className="store-selector-row">
          <Input
            onChange={searchChangeHandler}
            value={storeSearchValue}
            placeholder={t('searchStoreNameAndIdPlaceholder')}
            className="search-input"
          />
          <ArchivedFilter
            onClick={showArchivedStores.toggle}
            showArchived={showArchivedStores.value}
          />
          <RefreshButton
            onClick={getStoresRequest}
            buttonProps={{ size: 'middle' }}
          />
        </div>
        <CardGridWrapper
          cards={filteredStores?.map(store => (
            <div
              ref={
                store.StoreId === selectedStore?.StoreId
                  ? selectedStoreRef
                  : undefined
              }
              key={store.StoreId}
            >
              <StoreCard
                store={store}
                onClick={() => storeChangeHandler(store.StoreId)}
                getStores={getStoresRequest}
                isArchived={showArchivedStores.value}
                firstItem={
                  <FirstItemText
                    text={store.StoreName + ' - ' + store.StoreId}
                  />
                }
                isSelected={selectedStore?.StoreId === store.StoreId}
                hiddenButtons={[
                  StoreActionButtonEnums.ARCHIVE,
                  StoreActionButtonEnums.BOARDS,
                  StoreActionButtonEnums.CAMERAS,
                  StoreActionButtonEnums.CONNECT_CAMERAS,
                ]}
              />
            </div>
          ))}
          noData={filteredStores?.length === 0}
          status={stores.status}
          error={stores.ErrorMessage}
          containerClassName="homepage-entity-selector"
        />
      </div>
    </>
  );
}
