import { FilterItem } from '@Types/Pagination';
import { useTranslation } from 'react-i18next';

export function usePatternError() {
  const { t } = useTranslation();

  function getErrorMessageOfPattern(
    pattern: RegExp | undefined,
    value: FilterItem['value'],
    fieldLabel: string | undefined
  ) {
    if (typeof value !== 'string' || pattern === undefined) {
      return undefined;
    }

    const isFilterValid = pattern?.test(value) || !value;

    const filterPatternErrorMessage = t('filterPatternError', {
      field: fieldLabel,
    });

    // submit value as filter param only if pattern is matched
    const errorMessage = isFilterValid ? undefined : filterPatternErrorMessage;

    return errorMessage;
  }

  return { getErrorMessageOfPattern };
}
