import { Brand, GetAllAvailableBrandsParams } from '@Types/Brand';
import { VersionFile } from '@Types/Version';
import { CodeVersion } from '@Types/CodeVersion';
import model from './index';
import { City } from '@Types/City';
import { County } from '@Types/County';
import { Country } from '@Types/Country';
import { ConfigBase, ConfigId, ConfigSchemaWithoutId } from '@Types/Config';
import { AllAuditLogsFetchResponse, AuditLogsParams } from '@Types/AuditLogs';
import { Currency } from '@Types/Store';

class AdminService extends model {
  constructor() {
    super();
    this.Prefix('Admin');
  }

  /**
   * @description Get all available brands
   * @returns Promise
   */
  async GetAllAvailable(params?: GetAllAvailableBrandsParams) {
    let defaultParams: GetAllAvailableBrandsParams = {
      all: 1,
      isArchived: false,
    };
    if (params) {
      defaultParams = { ...defaultParams, ...params };
    }

    return this.Get('Brands').Params(defaultParams).Save<Brand[]>();
  }

  /**
   * @description Get code versions
   * @returns Promise
   */
  async GetCodeVersions() {
    return this.Get('CodeVersions').offset().Save<CodeVersion[]>();
  }

  /**
   * @description Get countries
   * @returns Promise
   */
  async GetCountries() {
    return this.Get('Countries').offset().Save<Country[]>();
  }

  async GetCities(countryId: number) {
    return this.Get(`Countries/${countryId}/Cities`).offset().Save<City[]>();
  }

  /**
   * @param {number} cityId
   * @description Get counties
   * @returns Promise
   */
  async GetCounties(cityId: number) {
    return this.Get(`Cities/${cityId}/Counties`).offset().Save<County[]>();
  }

  async GetAdminVersion(type: number, all: boolean) {
    return this.Get('Versions')
      .Params({ type, all: all ? 1 : 0 })
      .offset()
      .Save<VersionFile[]>();
  }

  /**
   * @description Get config schemas
   * @returns Promise
   */
  async GetConfigSchemas() {
    return this.Get('ConfigSchemas').Save<ConfigBase[]>();
  }

  /**
   * @description Get a config schema by its id
   * @returns Promise
   */
  async GetConfigSchema(id: ConfigId) {
    return this.Get(`ConfigSchemas/${id}`).Save<ConfigBase>();
  }

  /**
   * @description Delete a config schema by its id
   * @returns Promise
   */
  async DeleteConfigSchema(id: ConfigId) {
    return this.Delete(`ConfigSchemas/${id}`).Save();
  }

  /**
   * @description Update a config schema by its id
   * @returns Promise
   */
  async UpdateConfigSchema(id: ConfigId, newSchema: ConfigSchemaWithoutId) {
    return this.Put(`ConfigSchemas/${id}`).Data(newSchema).Save();
  }

  /**
   * @description Add a config schema
   * @returns Promise
   */
  async AddConfigSchema(newSchema: ConfigSchemaWithoutId) {
    return this.Post(`ConfigSchemas`).Data(newSchema).Save();
  }

  /**
   *
   * @param {AuditLogsParams} params
   * @description Used to fetch the actions users has taken in a given time range.
   * @returns Promise
   */
  async AuditLogs(params: AuditLogsParams) {
    return this.Post(`AuditLogs${params.dateTimeParams}`)
      .Data(params.filters)
      .offset()
      .Save<AllAuditLogsFetchResponse>();
  }

  async GetCurrencies() {
    return this.Get('Currencies').Save<Currency[]>();
  }
}

export default AdminService;
