import Status from '@Enums/Status';
import { useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { useTranslation } from 'react-i18next';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import FilledText from '@Components/FilledText';
import CameraActionButtons from '@Cards/CameraCard/Components/CameraActionButtons';
import { AllFetchedCameras, FetchedCamera } from '@Types/Camera';
import { convertInterfaceCameraEnumToText } from '@Utils/Camera';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import { apiCall, convertApiDate } from '@Utils/index';
import { InfoCircleOutlined } from '@ant-design/icons';
import useWindowSize from '@Hooks/useWindowSize';
import StatusBadge from '@Components/StatusBadge';
import { Tooltip } from '@Components/Tooltip';
import CamerasService from '@Services/Api/Cameras';
import CameraService from '@Services/Api/Camera';
import { Avatar, Image } from 'antd';

interface Params {
  cameras: AllFetchedCameras;
  isArchived: boolean;
  getCameras: () => void;
}

/**
 * @description This "hook" is not a generic hook. It's made to abstract the code and make it much more readable. It will only be used for <CamerasDetail />.
 */
export default function useCamerasColumns(
  params: Params
): ColumnsType<FetchedCamera> {
  const { cameras } = params;
  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BOARD_ACTION);
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const camerasTableColumns: ColumnsType<FetchedCamera> = useMemo(() => {
    if (cameras.status !== Status.success) return [];

    const columns: ColumnsType<FetchedCamera> = [
      {
        title: t('photograph'),
        dataIndex: 'Photo',
        render: (_, camera) => <CameraPhoto camera={camera} />,
        width: 136,
      },
      {
        title: t('name'),
        dataIndex: 'Name',
      },
      {
        title: t('status'),
        dataIndex: 'Status',
        render: (_, camera: FetchedCamera) => (
          <StatusBadge isOn={!camera.BoardPassive} />
        ),
      },
      {
        title: t('entityIds'),
        dataIndex: 'entityIds',
        render: (_: any, camera: FetchedCamera) => (
          <HierarchyItems
            brandId={camera.BrandId}
            storeId={camera.StoreId}
            boardId={camera.BoardId}
            cameraId={camera.Id}
            brand={camera.Brand}
            store={camera.Store}
            board={camera.Board}
            camera={camera.Name}
          />
        ),
        width: windowSize?.width && windowSize.width > 1360 ? 400 : 210,
      },
      {
        title: t('lastDataTime'),
        dataIndex: 'LastDataTime',
        render: (value: string) => (
          <FilledText text={value ? convertApiDate(value, true) : '-'} />
        ),
      },
      {
        title: t('version'),
        dataIndex: 'Version',
        render: (value: string) => <FilledText text={value} />,
      },
      {
        title: t('RTSP Url'),
        dataIndex: 'RTSPUrl',
        render: (RTSPUrl: string) =>
          RTSPUrl ? (
            <Tooltip title={RTSPUrl}>
              <InfoCircleOutlined />
            </Tooltip>
          ) : (
            '-'
          ),
      },
      {
        title: t('cameraType'),
        dataIndex: 'InterfaceType',
        render: (value: InterfaceCameraTypeEnums) => (
          <FilledText text={convertInterfaceCameraEnumToText(value)} />
        ),
      },
      {
        title: t('PhotoUploadedDate'),
        dataIndex: 'PhotoUploaded',
        render: (value: string) => <FilledText text={value} />,
      },
    ];

    if (boardActionPerm) {
      columns.push({
        title: t('actionButtons'),
        dataIndex: 'actionButtons',
        render: (_: any, camera: FetchedCamera) => (
          <CameraActionButtons {...params} camera={camera} />
        ),
      });
    }

    return columns;
  }, [cameras.status, windowSize.width]);

  return camerasTableColumns;
}

function CameraPhoto(props: { camera: FetchedCamera }) {
  const { camera } = props;

  const [cameraThumbnail, setCameraThumbnail] = useState<string>('');
  const [image, setImage] = useState<string>();

  useEffect(() => {
    getCameraThumbnail().then(thumbnail => {
      setCameraThumbnail(thumbnail || '');
    });
  }, []);

  const onClickThumbnail = async (show: boolean) => {
    if (show) {
      const image = await getPhoto();
      setImage(image);
    }
  };

  const getCameraThumbnail = async () => {
    try {
      return await new CamerasService().GetCameraThumbnail(camera.Id);
    } catch (e) {
      console.warn(e);
      return null;
    }
  };

  const getPhoto = async () => {
    const req = async () => await new CameraService().GetPhoto(camera.Id);

    try {
      const image = await apiCall(req, 'showImage', undefined, {
        hiddenStatuses: ['info', 'success'],
      });
      return image as string;
    } catch {
      return '';
    }
  };

  return (
    <Avatar
      shape="square"
      className="thumbnail-image"
      src={
        <Image
          preview={{
            src: image || 'images/image-not-found.png',
            onVisibleChange: onClickThumbnail,
          }}
          src={cameraThumbnail || 'images/image-not-found.png'}
        />
      }
    />
  );
}
