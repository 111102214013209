import Card from '@Components/Card';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AllReceiver } from '@Types/Receiver';
import ReceiverActionButtons from './ReceiverActionButtons';
import { Space, Typography } from 'antd';
import { useEffect } from 'react';
import { Tooltip } from '@Components/Tooltip';
import { CardDetails } from '@Components/Card/types';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const { Title } = Typography;
type Props = {
  receiver: AllReceiver;
  isArchived: boolean;
  getReceiver: Function;
};

export default function ReceiverCard({
  receiver,
  isArchived,
  getReceiver,
}: Props) {
  const { t } = useTranslation();

  const details: CardDetails = [
    {
      key: { text: t('Name') },
      value: {
        text: receiver.Name + ' (' + receiver.Id + ')',
      },
    },
    {
      key: { text: 'BtMacAdress' },

      value: {
        text: receiver.BTMacAddress ? receiver.BTMacAddress : '-',
      },
    },
    {
      key: { text: t('ReceiverType') },
      value: {
        text: t(receiver.ReceiverType || ''),
      },
    },
    {
      key: {
        text: (
          <Space size={1} align="center">
            <Typography.Text>
              {t('shrinkDataFromLineEntranceShort')}
            </Typography.Text>
            <Tooltip title={t('shrinkDataFromLineEntrance')}>
              <InfoCircleOutlined style={{ color: 'gray' }} />
            </Tooltip>
          </Space>
        ),
      },
      value: {
        text:
          receiver.ShrinkDataFromLineEntrance === 1 ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <CloseCircleOutlined style={{ color: 'red' }} />
          ),
      },
    },
  ];

  useEffect(() => {
    if (isArchived)
      details.push({
        key: { text: t('ArchivingReason') },
        value: {
          text: t(receiver.ArchivingReason || ''),
        },
      });
  }, [isArchived]);

  return (
    <Card
      subHeader={true}
      extraButtons={
        <ReceiverActionButtons
          receiver={receiver}
          isArchived={isArchived}
          getReceiver={getReceiver}
        />
      }
      content={{
        firsItem: {
          custom: (
            <Tooltip title={`${receiver.Brand}/${receiver.Store}`}>
              <Title level={5} className="first-item-text">
                {`${receiver.Brand}/${receiver.Store}`}
              </Title>
            </Tooltip>
          ),
        },
        secondItem: {
          text: receiver.ExternalId,
        },
        subItem: {
          text: receiver.BatteryUpdated
            ? receiver.Battery +
              ' (' +
              moment(receiver.BatteryUpdated).format('L') +
              ' ' +
              moment(receiver.BatteryUpdated).format('LT ') +
              ')'
            : receiver.Battery + ' ( )',
        },
      }}
      details={details}
    />
  );
}
