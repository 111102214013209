import { ReactElement, useMemo } from 'react';
import { Layout, Menu } from 'antd';
import {
  TeamOutlined,
  HomeOutlined,
  BarChartOutlined,
  WifiOutlined,
  PartitionOutlined,
  ShopOutlined,
  CameraOutlined,
  AimOutlined,
  ApartmentOutlined,
  VideoCameraOutlined,
  GatewayOutlined,
  AlertOutlined,
  ControlOutlined,
  SettingOutlined,
  ContainerOutlined,
  SolutionOutlined,
  NotificationOutlined,
  SearchOutlined,
  PaperClipOutlined,
  EnvironmentOutlined,
  AppstoreOutlined,
  OneToOneOutlined,
  MessageOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import './style.scss';
import {
  onSidebarHiddenToggle,
  onSidebarToggle,
} from '@Store/Dashboard/action';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Permissions } from '@Enums/Permission';
import { MenuItemBase, MenuItemParent, MenuItems } from '@Types/Dashboard';
import { Link } from 'react-router-dom';
import useIsEnvAdmin from '@Hooks/useIsEnvAdmin';
import config from '~../../../config';

const { Sider } = Layout;

function Navbar(): ReactElement {
  const { isSidebarCollapsed, isSidebarHidden } = useAppSelector(
    state => state.Dashboard
  );
  const userDetails = useAppSelector(
    state => state.User.currentUserDetails.data
  );
  const permissions = useAppSelector(
    state => state.Permission.userPermissions.data.Permissions
  );

  const isEnvAdmin = useIsEnvAdmin();
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useAppDispatch();

  const navbarCollapseHandler = () => {
    dispatch(onSidebarToggle(!isSidebarCollapsed));
  };

  const handleMenuItemClick = (path: string): void => {
    navigate(path);
  };

  const defaultKey = (path: string): string => {
    // path: '/', '/user/3', '/user/new-user etc.
    if (path === '/') return '/';

    // Get the root key.
    // patKey is 'user' for '/user/new-user'
    const pathKey = path.split('/')[1];

    const menuKey = matchedMenuKey(pathKey);
    if (menuKey) return menuKey;

    const subMenuKey = matchedSubMenuKey(pathKey);
    if (subMenuKey) return subMenuKey;

    return '';
  };

  const matchedMenuKey = (pathKey: string): null | string => {
    const matchedMenuItem = menuItems.find(i => i.key.includes(pathKey));
    if (matchedMenuItem) return matchedMenuItem.key;

    return null;
  };

  const matchedSubMenuKey = (pathKey: string): null | string => {
    const menus = menuItems.filter(i => !!i.subMenus);

    const matchedMenus = menus.find(menu =>
      menu.subMenus?.find(subMenu => subMenu.key.includes(pathKey))
    );

    const matchedSubMenus = matchedMenus?.subMenus;
    const matchedSubMenu = matchedSubMenus?.find(m => m.key.includes(pathKey));
    const matchedKey = matchedSubMenu?.key;

    if (matchedKey) return matchedKey;

    return null;
  };

  const handleSiderBreakpoint = (shouldCollapse: boolean): void => {
    dispatch(onSidebarToggle(shouldCollapse));
    dispatch(onSidebarHiddenToggle(shouldCollapse));
  };

  const includesPermission = (
    item: MenuItemBase | MenuItemParent
  ): boolean | undefined => {
    if (!item.permission) return true;
    if (!userDetails.IsCustomRole) return true;

    return permissions?.some(p => p.Name === item.permission);
  };

  const menuItems: MenuItems = useMemo(() => {
    const items: MenuItems = [
      {
        key: '/',
        icon: <AppstoreOutlined />,
        translationKey: 'home',
      },
      {
        key: 'brands-parent',
        icon: <HomeOutlined />,
        translationKey: 'brands',
        subMenus: [
          {
            key: 'brands',
            icon: <HomeOutlined />,
            translationKey: 'brands',
            permission: Permissions.ADMIN_BRAND_VIEW,
          },
          {
            key: 'region',
            icon: <EnvironmentOutlined />,
            translationKey: 'regions',
            permission: Permissions.ADMIN_REGION_VIEW,
          },
          {
            key: 'brand-groups',
            icon: <GroupOutlined />,
            translationKey: 'groups',
            permission: Permissions.ADMIN_BRAND_VIEW,
          },
        ],
      },
      {
        key: 'all-stores',
        icon: <ShopOutlined />,
        translationKey: 'stores',
        permission: Permissions.ADMIN_STORE_VIEW,
      },
      {
        key: 'boards-parent',
        icon: <AimOutlined />,
        translationKey: 'boards',
        subMenus: [
          {
            key: 'all-boards',
            icon: <AimOutlined />,
            translationKey: 'boards',
            permission: Permissions.ADMIN_BOARD_VIEW,
          },
          {
            key: 'versions',
            icon: <PartitionOutlined />,
            translationKey: 'versions',
            hide: !isEnvAdmin,
          },
          {
            key: 'hardware',
            icon: <OneToOneOutlined />,
            translationKey: 'hardwares',
            hide: !isEnvAdmin,
          },
        ],
      },
      {
        key: 'cameras-parent',
        icon: <CameraOutlined />,
        translationKey: 'cameras',
        subMenus: [
          {
            key: 'all-cameras',
            icon: <CameraOutlined />,
            translationKey: 'cameras',
            permission: Permissions.ADMIN_CAMERA_VIEW,
          },
          {
            key: 'data',
            icon: <BarChartOutlined />,
            translationKey: 'datas',
            permission: Permissions.ADMIN_COORDINATES_VIEW,
          },
          {
            key: 'camera-records',
            icon: <VideoCameraOutlined />,
            translationKey: 'recordings',
            permission: Permissions.ADMIN_CAMERA_RECORDS_VIEW,
          },
          {
            key: 'lens',
            icon: <AimOutlined />,
            translationKey: 'lenses',
            hide: !isEnvAdmin,
          },
          {
            key: 'notification-rules',
            icon: <NotificationOutlined />,
            translationKey: 'notificationRules',
            hide: !isEnvAdmin,
          },
        ],
      },
      {
        key: 'beacons-parent',
        icon: <WifiOutlined />,
        translationKey: 'navigation.beacons',
        subMenus: [
          {
            key: 'beacon',
            icon: <WifiOutlined />,
            translationKey: 'navigation.beacons',
            permission: Permissions.ADMIN_BEACON_VIEW,
          },
          {
            key: 'receiver',
            icon: <AimOutlined />,
            translationKey: 'navigation.receiver',
            permission: Permissions.ADMIN_RECEIVER_VIEW,
          },
          {
            key: 'beacondata',
            icon: <AlertOutlined />,
            translationKey: 'datas',
            permission: Permissions.ADMIN_COORDINATES_VIEW,
          },
          {
            key: 'gateways',
            icon: <GatewayOutlined />,
            translationKey: 'navigation.gateways',
            permission: Permissions.ADMIN_GATEWAY_VIEW,
          },
          {
            key: 'beacon-rules',
            icon: <NotificationOutlined />,
            translationKey: 'notificationRules',
            permission: Permissions.ADMIN_BEACON_RULES_VIEW,
          },
        ],
      },
      {
        key: 'users',
        icon: <TeamOutlined />,
        translationKey: 'users',
        subMenus: [
          {
            key: 'users',
            icon: <TeamOutlined />,
            translationKey: 'users',
            permission: Permissions.ADMIN_USERS_VIEW,
          },
          {
            key: 'roles',
            icon: <ApartmentOutlined />,
            translationKey: 'roles',
            permission: Permissions.ADMIN_CUSTOM_ROLE_VIEW,
          },
          {
            key: 'page-logs',
            icon: <ContainerOutlined />,
            translationKey: 'pageLogs',
            hide: !isEnvAdmin,
          },
          {
            key: 'audit-logs',
            icon: <SolutionOutlined />,
            translationKey: 'auditLogs',
            hide: !isEnvAdmin,
          },
        ],
      },
      {
        key: 'reports',
        icon: <PaperClipOutlined />,
        translationKey: 'autoReports',
        hide: !isEnvAdmin,
      },
      {
        key: 'tickets',
        icon: <MessageOutlined />,
        translationKey: 'tickets',
        permission: Permissions.ADMIN_TICKET_VIEW,
      },
    ];

    // add settings if is env admin
    if (isEnvAdmin) {
      items.push({
        key: 'settings',
        icon: <SettingOutlined />,
        translationKey: 'systemSettings',
        subMenus: [
          {
            key: 'configs',
            icon: <ControlOutlined />,
            translationKey: 'configSchemas',
          },
          {
            key: 'field-searches',
            icon: <SearchOutlined />,
            translationKey: 'fieldSearches.navbarName',
          },
        ],
      });
    }

    return items;
  }, [isEnvAdmin]);

  return (
    <Sider
      hidden={isSidebarHidden}
      collapsible
      collapsed={isSidebarCollapsed}
      onCollapse={navbarCollapseHandler}
      className={`sidebar-wrapper custom-scrollbar ${
        config.isTestEnv && 'scroll-test sidebar-wrapper-test'
      }`}
      breakpoint="md"
      onBreakpoint={handleSiderBreakpoint}
    >
      <Menu
        theme="dark"
        defaultSelectedKeys={[defaultKey(location.pathname)]}
        mode="inline"
        className="sidebar-container"
      >
        {menuItems.filter(includesPermission).map(item => {
          return item.subMenus
            ? item.subMenus.filter(includesPermission).length > 0 && (
                <SubMenu
                  key={item.key}
                  icon={item.icon}
                  title={t(item.translationKey)}
                >
                  {item.subMenus.filter(includesPermission).map(
                    subMenu =>
                      !subMenu.hide && (
                        <Menu.Item key={subMenu.key} icon={subMenu.icon}>
                          <Link to={subMenu.key}>
                            {t(subMenu.translationKey)}
                          </Link>
                        </Menu.Item>
                      )
                  )}
                </SubMenu>
              )
            : !item.hide && (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  onClick={() => handleMenuItemClick(item.key)}
                >
                  <Link to={item.key}>
                    {t(`navigation.${item.translationKey}`)}
                  </Link>
                </Menu.Item>
              );
        })}
      </Menu>
    </Sider>
  );
}

export default Navbar;
