import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from 'antd';
import {
  ShopOutlined,
  IdcardOutlined,
  HomeOutlined,
  GroupOutlined,
  ControlOutlined,
  AimOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ModalFooter from '@Components/ModalFooter';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useMemo, useState } from 'react';
import './style.scss';
import { getCurrencies, setSelectedStore } from '@Store/Store/action';
import { EditedStore, NewStore, Store } from '@Types/Store';
import { setSelectedCountry } from '@Store/Country/action';
import { getCities, setSelectedCity } from '@Store/City/action';
import { getCounties, setSelectedCounty } from '@Store/County/action';
import { CardEditItemProps } from '@Components/CardEditInfo/CardEditItem';
import CardEditInfo from '@Components/CardEditInfo';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import { convertConfigTypeToEnum } from '@Utils/Config';
import JsonSchemaForm from '@Components/JsonSchema/Form';
import { IChangeEvent } from '@rjsf/core';
import { ConfigSchemaId, OptionType } from '@Types/Config';
import SearchableSelect from '@Components/SearchableSelect';
import { DefaultOptionType } from 'antd/lib/select';
import DevicesSelector from '@Components/DevicesSelector';
import { DevicesData } from '@Types/Devices';
import DevicesTable from '@Components/DevicesTable';
import { useParams } from 'react-router-dom';
import { getBrandRegions } from '@Store/Regions/action';
import { getCodeVersions } from '@Store/CodeVersion/action';
import { getAllHardware, getBoardBrandCameras } from '@Store/Hardware/action';
import { resetCurrentBoard } from '@Store/Board/action';
import { StoreProduct } from '@Enums/Store';
import { useSelectAll } from '@Hooks/useSelectAll';
import { RJSFSchema, ValidationData } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import { interFaceCameraTypes } from '@Utils/index';
import { BoardAdapterBrand, BoardColor, BoardRaySpotType } from '@Enums/Board';
import { enumToIterable } from '@Utils/helpers';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';
import { LocationPicker } from '@Components/LocationPicker';
import useBoolean from '@Hooks/useBoolean';

const { Option } = Select;
const { Panel } = Collapse;

type Props = {
  store?: Store;
  onFinish: (store: EditedStore | NewStore) => void;
};

interface IForm extends EditedStore {
  StoreProductList?: StoreProduct[];
}

export default function StoreDetail({ onFinish, store }: Props) {
  const [infoItems, setInfoItems] = useState<CardEditItemProps[]>([]);
  const [selectedConfigId, setSelectedConfigId] = useState<ConfigSchemaId>(
    store?.ConfigSchemaId || null
  );
  const [boardConfigJson, setBoardConfigJson] = useState<
    RJSFSchema | undefined
  >(store?.BoardConfigJson ? JSON.parse(store.BoardConfigJson) : {});

  const selectedCountry = useAppSelector(s => s.Country.selectedCountry);
  const selectedCity = useAppSelector(s => s.City.selectedCity);
  const selectedCounty = useAppSelector(s => s.County.selectedCounty);

  const countries = useAppSelector(s => s.Country.countries.data);
  const cities = useAppSelector(s => s.City.cities.data);
  const counties = useAppSelector(s => s.County.counties.data);
  const configSchemas = useAppSelector(s => s.Config.configSchemas.data);

  const codeVersions = useAppSelector(s => s.CodeVersion.codeVersions.data);
  const allHardware = useAppSelector(s => s.Hardware.allHardware);
  const boardBrandCameras = useAppSelector(s => s.Hardware.boardBrandCameras);

  const [devices, setdevices] = useState<DevicesData>(store?.Devices || []);
  const [validationData, setValidationData] = useState<
    ValidationData<any> | undefined
  >();

  const { brandId } = useParams();
  const regions = useAppSelector(s => s.Regions.regions);
  const currencies = useAppSelector(s => s.Store.currencies);

  useEffect(() => {
    if (store?.CountryId) handleCountryChange(store.CountryId);
    if (store?.CityId) handleCityChange(store.CityId);
    if (store?.CountyId) handleCountyChange(store.CountyId);
    dispatch(getCodeVersions());
    getHardwares();
    dispatch(getBoardBrandCameras());
    dispatch(getCurrencies());

    return () => {
      dispatch(setSelectedStore({} as Store));
      dispatch(setSelectedCountry(null));
      dispatch(setSelectedCity(null));
      dispatch(setSelectedCounty(null));
      dispatch(resetCurrentBoard());
    };
  }, []);

  const getHardwares = () => {
    dispatch(getAllHardware(noPaginationParams));
  };

  useEffect(() => {
    dispatch(getBrandRegions(parseInt(brandId!)));
  }, [brandId]);

  const brandPerm = useCheckPermission(Permissions.ADMIN_BRAND_VIEW);
  const [form] = useForm();
  const CreateCamera = Form.useWatch('CreateCamera', form);
  const coordinates = Form.useWatch('Coordinates', form);

  const showLocationPickerState = useBoolean(!store);

  useEffect(() => {
    setInfoItems([
      {
        icon: <HomeOutlined />,
        name: store?.Brand!,
        id: store?.BrandId!,
        routeTo: `/brands/${store?.BrandId}/stores`,
        hasPermission: brandPerm,
      },
    ]);

    form.resetFields();
  }, [store?.Id]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCountryChange = (countryId: number) => {
    const country = countries.find(c => c.Id === countryId);

    dispatch(setSelectedCountry(country || null));
    dispatch(setSelectedCity(null));
    dispatch(setSelectedCounty(null));
    dispatch(getCities(countryId));
    form.setFieldsValue({ CountryId: countryId });
    form.setFieldsValue({ CityId: null });
    form.setFieldsValue({ CountyId: null });
  };

  const handleCityChange = (cityId: number) => {
    const city = cities.find(c => c.Id === cityId);

    dispatch(setSelectedCity(city || null));
    dispatch(setSelectedCounty(null));
    dispatch(getCounties(cityId));
    form.setFieldsValue({ CityId: cityId });
    form.setFieldsValue({ CountyId: null });
  };

  const handleCountyChange = (countyId: number) => {
    const county = counties.find(c => c.Id === countyId);

    dispatch(setSelectedCounty(county || null));
    form.setFieldsValue({ CountyId: countyId });
  };

  const handleConfigChange = (
    _: any,
    option: DefaultOptionType | DefaultOptionType[]
  ) => {
    setBoardConfigJson(undefined);

    const castedOption = option as OptionType;
    setSelectedConfigId(castedOption?.value);
  };

  const jsonFormOnChange = (e: IChangeEvent<RJSFSchema>) => {
    setBoardConfigJson(e.formData!);
  };

  function handleCameraTypeChange(value: InterfaceCameraTypeEnums) {
    const boardBrandCamera = boardBrandCameras?.data?.find(
      b =>
        b.CameraInterfaceType === value &&
        b.BoardBrandModelId === BoardBrandModelId
    );

    if (boardBrandCamera) {
      form.setFieldsValue({ RTSPUrl: boardBrandCamera.DefaultURL });
    }
  }

  const handleSubmit = (formValues: IForm) => {
    const editedStore = {
      ...formValues,
      ConfigSchemaId: selectedConfigId,
      BoardConfigJson: JSON.stringify(boardConfigJson),
      PartnerId: formValues.PartnerId,
      Devices: devices,
      Products: formValues.StoreProductList
        ? {
            CheckoutProduct:
              formValues.StoreProductList?.some(
                s => s === StoreProduct.CheckoutProduct
              ) ?? false,
            InstoreAnalyticsProduct:
              formValues.StoreProductList?.some(
                s => s === StoreProduct.InstoreAnalyticsProduct
              ) ?? false,
            PeopleCountingGenderAgeProduct:
              formValues.StoreProductList?.some(
                s => s === StoreProduct.PeopleCountingGenderAgeProduct
              ) ?? false,
            PeopleCountingProduct:
              formValues.StoreProductList?.some(
                s => s === StoreProduct.PeopleCountingProduct
              ) ?? false,
            PersonnelTrackingProduct:
              formValues.StoreProductList?.some(
                s => s === StoreProduct.PersonnelTrackingProduct
              ) ?? false,
          }
        : undefined,
    };

    if (store) {
      delete editedStore.Products;
    }

    delete editedStore?.StoreProductList;

    if (!formValues?.CreateCamera) {
      if (formValues?.CameraType !== undefined) {
        delete formValues['CameraType'];
      }

      if (formValues?.RTSPUrl !== undefined) {
        delete formValues['RTSPUrl'];
      }
    }

    onFinish(editedStore);
  };
  const onChangeDevices = (newDevices: DevicesData) => {
    setdevices(newDevices);
  };
  const handleDeleteDevicesClick = (selectIndex: number) => {
    const filterDevices = devices.filter(
      (value, index, array) => index !== selectIndex
    );
    setdevices(filterDevices);
  };

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];
  const BoardBrandModelId = Form.useWatch('BoardBrandModelId', form);

  const storeProductList = Form.useWatch('StoreProductList', form) as
    | StoreProduct[]
    | undefined;

  const storeProductOptionsSelectProps = useSelectAll({
    value: storeProductList,
    onChange: StoreProductList => form.setFieldsValue({ StoreProductList }),
    options: Object.keys(StoreProduct).map(value => ({
      label: t(value),
      value,
    })),
  });

  const configSchema = useMemo(() => {
    if (!selectedConfigId) return null;

    try {
      return JSON.parse(
        configSchemas.find(c => c.Id === selectedConfigId)?.Schema!
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [configSchemas, selectedConfigId]);

  const cameraTypeOptions = interFaceCameraTypes.map(cameraType => ({
    label: cameraType.type,
    value: cameraType.value,
  }));

  const currencyOptions = currencies.data?.map(currency => ({
    label: currency.Name,
    value: currency.Id,
  }));

  const boardColorOptions = enumToIterable(BoardColor).map(
    ([label, value]) => ({
      label: t(`BoardColors.${label}`),
      value,
    })
  );

  const boardRaySpotTypeOptions = enumToIterable(BoardRaySpotType).map(
    ([label, value]) => ({
      label: t(`BoardRaySpotTypes.${label}`),
      value,
    })
  );

  const boardAdapterBrandOptions = enumToIterable(BoardAdapterBrand).map(
    ([label, value]) => ({
      label: t(`BoardAdapterBrands.${label}`),
      value,
    })
  );

  return (
    <>
      <Form
        form={form}
        name="store-form"
        onFinish={handleSubmit}
        className="store-detail-form-container"
        initialValues={{
          ...store,
          Coordinates:
            typeof store?.LatDouble === 'number' &&
            typeof store?.LngDouble === 'number'
              ? {
                  lat: store?.LatDouble,
                  lng: store?.LngDouble,
                }
              : undefined,
        }}
      >
        {store && <CardEditInfo items={infoItems} />}

        <Form.Item label={t('name')} name="Name" rules={inputRule}>
          <Input prefix={<ShopOutlined />} />
        </Form.Item>
        <br />

        <Form.Item label={t('country')} name="CountryId" rules={inputRule}>
          <SearchableSelect
            placeholder={t('country')}
            value={selectedCountry?.Id}
            onChange={handleCountryChange}
          >
            {countries.map(c => (
              <Select.Option key={c.Id} value={c.Id} label={c.Name}>
                {c.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>

        {!store && (
          <Form.Item
            rules={inputRule}
            label={t('storeProducts')}
            name="StoreProductList"
          >
            <SearchableSelect
              {...storeProductOptionsSelectProps}
              mode="multiple"
              allowClear
            />
            {storeProductList?.some(
              p => p === StoreProduct.PersonnelTrackingProduct
            ) && (
              <Alert
                showIcon
                type="warning"
                message={t('personnelTrackingSelectAtLeastOneReceiver')}
              />
            )}
          </Form.Item>
        )}

        <Form.Item label={t('city')} name="CityId">
          <SearchableSelect
            placeholder={t('city')}
            value={selectedCity?.Id}
            onChange={handleCityChange}
          >
            {cities.map(c => (
              <Select.Option key={c.Id} value={c.Id} label={c.Name}>
                {c.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
        <Form.Item label={t('county')} name="CountyId">
          <SearchableSelect
            placeholder={t('county')}
            value={selectedCounty?.Id}
            onChange={handleCountyChange}
          >
            {counties.map(c => (
              <Select.Option key={c.Id} value={c.Id} label={c.Name}>
                {c.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
        <Form.Item label={t('region')} name="RegionId">
          <SearchableSelect placeholder={t('region')}>
            {regions.data.map(c => (
              <Select.Option key={c.Id} value={c.Id} label={c.Name}>
                {c.Name}
              </Select.Option>
            ))}
          </SearchableSelect>
        </Form.Item>
        <Form.Item label="Partner Id" name="PartnerId">
          <Input prefix={<IdcardOutlined />} />
        </Form.Item>
        <Form.Item label="Occupancy Threshold" name="OccupancyThreshold">
          <Input suffix={t('seconds')} />
        </Form.Item>
        <br />

        <Form.Item
          label={t('hideFromReport')}
          name="HideFromReport"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={t('saleIntegrated')}
          name="SaleIntegrated"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item label={t('currency')} name="CurrencyId">
          <SearchableSelect options={currencyOptions} allowClear />
        </Form.Item>

        <Divider />
        <Form.Item name="Devices" label={t('devices')}>
          <DevicesSelector onSubmit={onChangeDevices} devices={devices} />
        </Form.Item>
        {devices.length > 0 && (
          <DevicesTable
            data={devices}
            handleDeleteDevicesClick={handleDeleteDevicesClick}
          />
        )}

        <Form.Item
          name="Coordinates"
          label={t('coordinates')}
          className="coordinates-form-item"
          tooltip={t('storeCoordinatesInfo')}
          rules={inputRule}
        >
          {showLocationPickerState.value && (
            <LocationPicker
              defaultCoordinates={coordinates}
              onMarkerChange={Coordinates => {
                form.setFieldsValue({ Coordinates });
              }}
            />
          )}
          <Button
            style={{ marginTop: showLocationPickerState.value ? 8 : 0 }}
            type="primary"
            onClick={
              showLocationPickerState.value
                ? showLocationPickerState.setFalse
                : showLocationPickerState.setTrue
            }
          >
            {t(showLocationPickerState.value ? 'hideMap' : 'showMap')}
          </Button>
        </Form.Item>

        <Divider />
        <Form.Item label={t('config')}>
          <SearchableSelect
            allowClear
            onChange={handleConfigChange}
            defaultValue={selectedConfigId}
          >
            {configSchemas
              .filter(
                c => c.ConfigSchemaType === convertConfigTypeToEnum('store')
              )
              .map(c => (
                <Option value={c.Id} key={c.Id} label={c.Name}>
                  {c.Name}
                </Option>
              ))}
          </SearchableSelect>
        </Form.Item>

        <Form.Item
          name="BoardConfig"
          rules={[
            {
              validator: async () => {
                if (!selectedConfigId) return Promise.resolve();

                const validatedData = validator.validateFormData(
                  boardConfigJson,
                  configSchema
                );

                if (validatedData.errors.length === 0) {
                  setValidationData(undefined);
                  return Promise.resolve();
                }

                setValidationData(validatedData);
                return Promise.reject(t('notValidConfig'));
              },
            },
          ]}
        />

        <Form.Item labelCol={{ offset: 2 }} label={t('boardConfig')}>
          {selectedConfigId ? (
            <JsonSchemaForm
              schema={configSchema}
              onChange={jsonFormOnChange}
              formData={boardConfigJson}
              omitExtraData
              liveOmit
              extraErrors={validationData?.errorSchema}
            />
          ) : (
            '-'
          )}
        </Form.Item>

        {!store && (
          <>
            <Divider />
            <Collapse className="BoardCollapse" bordered>
              <Panel header="Kart Ekle" key="1">
                <Form.Item label={t('board')} name="CardName" rules={inputRule}>
                  <Input prefix={<AimOutlined />} />
                </Form.Item>

                <Form.Item
                  label={t('BoardBrand')}
                  name="BoardBrandModelId"
                  rules={inputRule}
                >
                  <SearchableSelect
                    placeholder={t('Select')}
                    onChange={() => {
                      form.setFieldsValue({ CodeVersionId: undefined });
                    }}
                  >
                    {allHardware &&
                      allHardware?.data?.Data?.map(hardWare => (
                        <Select.Option
                          key={hardWare.Id}
                          value={hardWare.Id}
                          label={hardWare.Name}
                        >
                          {hardWare.Name}
                        </Select.Option>
                      ))}
                  </SearchableSelect>
                </Form.Item>

                <Form.Item
                  label={t('codeVersion')}
                  name="CodeVersionId"
                  rules={inputRule}
                >
                  <SearchableSelect
                    placeholder={t('selectCodeVersion')}
                    suffixIcon={<ControlOutlined />}
                  >
                    {codeVersions
                      .filter(
                        codeVersion =>
                          codeVersion.HardwareId === BoardBrandModelId
                      )
                      .map(codeVersion => (
                        <Select.Option
                          key={codeVersion.Id}
                          value={codeVersion.Id}
                          label={`${codeVersion.Name} (${codeVersion.Version})`}
                        >
                          {`${codeVersion.Name} (${codeVersion.Version})`}
                        </Select.Option>
                      ))}
                  </SearchableSelect>
                </Form.Item>

                <Form.Item label={t('color')} name="Color" rules={inputRule}>
                  <Select options={boardColorOptions} />
                </Form.Item>

                <Form.Item label={t('raySpotType')} name="RaySpotType">
                  <Select allowClear options={boardRaySpotTypeOptions} />
                </Form.Item>

                <Form.Item label={t('adapterBrand')} name="AdapterBrand">
                  <Select allowClear options={boardAdapterBrandOptions} />
                </Form.Item>

                <Form.Item label={t('group')} name="Group">
                  <Input prefix={<GroupOutlined />} />
                </Form.Item>

                <Form.Item label={t('countForNewBoard')} name="Count">
                  <Input type="number" min={0} max={25} />
                </Form.Item>

                <Form.Item
                  label={t('createCamera')}
                  name="CreateCamera"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                {CreateCamera && (
                  <>
                    <Form.Item
                      label={t('cameraType')}
                      name="InterfaceType"
                      rules={inputRule}
                    >
                      <Select
                        options={cameraTypeOptions}
                        onChange={handleCameraTypeChange}
                      />
                    </Form.Item>
                    <Form.Item label="RTSP Url" name="RTSPUrl">
                      <Input />
                    </Form.Item>
                  </>
                )}

                <p>{t('additionalDevices')} :</p>
                <Row>
                  <Col span={6}>
                    <Form.Item name="IsHasWifiDongle" valuePropName="checked">
                      <Checkbox> Wifi Dongle</Checkbox>
                    </Form.Item>
                  </Col>{' '}
                  <Col span={6}>
                    <Form.Item
                      name="IsHasBluetoothDongle"
                      valuePropName="checked"
                    >
                      <Checkbox> Bluetooth Dongle</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="IsHasPoeSplitter" valuePropName="checked">
                      <Checkbox> Poe Splitter</Checkbox>
                    </Form.Item>
                  </Col>{' '}
                  <Col span={6}>
                    <Form.Item name="IsHasHelperBoard" valuePropName="checked">
                      <Checkbox>Helper Board</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </>
        )}
      </Form>
      <Divider />

      <ModalFooter formKey="store-form" />
    </>
  );
}
