import { Button, Card, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as FilterTypes from '@Types/Filter';
import SortByFilter from '../SortByFilter';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import ArchivedFilter from '../../../../Components/ArchivedFilter';
import GoBackButton from '@Components/GoBackButton';
import { PlusCircleOutlined } from '@ant-design/icons';
import { GetBoardsParams } from '@Types/Board';
import BoardCard from '@Cards/BoardCard';
import './style.scss';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import useDataDisplay from '@Hooks/useDataDisplay';
import DataDisplayTable from '@Components/DataDisplayTable';
import DataDisplayToggler from '@Components/DataDisplayToggler';
import useBoardsColumns from './useBoardsColumns';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { getCodeVersions } from '@Store/CodeVersion/action';
import Status from '@Enums/Status';
import { InstallationStatus } from '@Enums/Board';

type Props = {
  getUnarchivedBoardsAction: Function;
  getArchivedBoardsAction: Function;
  title: string;
};

const BoardsDetail = ({
  getUnarchivedBoardsAction,
  getArchivedBoardsAction,
  title,
}: Props) => {
  const location = useLocation();
  const [showArchivedBoards, setShowArchivedBoards] = useState<boolean>(
    location.state?.showArchived
  );
  const fetchedBoards = useAppSelector(s => s.Board.fetchedBoards);
  const codeVersions = useAppSelector(s => s.CodeVersion.codeVersions);
  const [currentDisplay, toggleDisplay] = useDataDisplay('boards');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { storeId } = useParams();

  useEffect(() => {
    dispatch(getCodeVersions());
  }, []);

  useSetHeaderTitle(
    `${title} - ${
      showArchivedBoards ? t('archivedBoards') : t('unarchivedBoards')
    }`
  );

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    { field: 'Name', label: t('name'), type: FilterType.TEXT },
    {
      field: 'CodeVersionId',
      label: t('codeVersion'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      loading: codeVersions?.status === Status.pending,
      options: codeVersions?.data?.map(u => ({
        label: u.Name,
        value: u.Id,
      })),
    },
    {
      field: 'IP',
      label: 'IP',
      type: FilterType.TEXT,
      pattern: /^(\d{1,3}\.){3}\d{1,3}$/,
    },
    {
      field: 'WifiMac',
      label: 'Wifi Mac',
      type: FilterType.TEXT,
      pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
    },
    {
      field: 'EthernetMac',
      label: 'Ethernet Mac',
      type: FilterType.TEXT,
      pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
    },
    {
      field: 'BluetoothMac',
      label: 'Bluetooth Mac',
      type: FilterType.TEXT,
      pattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
    },
    {
      field: 'InstallationStatus',
      label: t('installedStatus'),
      type: FilterType.RADIO,
      radioOptions: [
        { label: t('hadInstalled'), value: InstallationStatus.Installed },
        { label: t('notHadInstalled'), value: InstallationStatus.NotInstalled },
        {
          label: t('waitingCustomer'),
          value: InstallationStatus.WaitingCustomer,
        },
        { label: t('onTheWay'), value: InstallationStatus.OnTheWay },
        { label: t('all'), value: undefined },
      ],
    },
    {
      field: 'Passive',
      label: t('status'),
      type: FilterType.RADIO,
      radioOptions: [
        { label: t('off'), value: true },
        { label: t('on'), value: false },
        { label: t('all'), value: undefined },
      ],
    },
    {
      field: 'IsHasHelperBoard',
      label: 'Helper Board',
      type: FilterType.RADIO,
      radioOptions: [
        { label: t('exists'), value: true },
        { label: t('notExists'), value: false },
        { label: t('all'), value: undefined },
      ],
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'boards',
    {
      orderBy: 'asc',
      sortKey: 'Name',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: fetchedBoards.status,
    filterOptions,
    pageConfigResult,
    refetchDependencies: [showArchivedBoards],
    queryFunction: p => getBoards(p),
  });

  const boardActionPerm = useCheckPermission(Permissions.ADMIN_BOARD_ACTION);
  const toggleShowArchievedBoards = () => {
    setShowArchivedBoards(p => !p);
  };

  const getBoards = (filters: FilterTypes.PaginationParams) => {
    const params: GetBoardsParams = { filters, storeId };

    if (showArchivedBoards) {
      dispatch(getArchivedBoardsAction(params));
    } else {
      dispatch(getUnarchivedBoardsAction(params));
    }
  };

  const boardsTableColumns = useBoardsColumns({
    boards: fetchedBoards,
    getBoards: refetch,
    isArchived: showArchivedBoards,
  });

  return (
    <Card className="board-detail-container">
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <GoBackButton />
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />

          <DataDisplayToggler
            currentDisplay={currentDisplay}
            onToggle={toggleDisplay}
          />
          <ArchivedFilter
            onClick={toggleShowArchievedBoards}
            showArchived={showArchivedBoards}
          />
          {storeId && (
            <Link to="new-board">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                disabled={!boardActionPerm}
              >
                {t('addBoard')}
              </Button>
            </Link>
          )}
        </div>

        <div className="card-grid-container">
          <CardGridWrapper
            status={fetchedBoards.status}
            noData={fetchedBoards.data?.Data?.length === 0}
            cards={
              currentDisplay === 'card' ? (
                <React.Fragment>
                  {fetchedBoards.data?.Data?.map(b => (
                    <BoardCard
                      board={b}
                      key={b.Id}
                      isArchived={showArchivedBoards}
                      getBoards={refetch}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <DataDisplayTable
                  columns={boardsTableColumns}
                  dataSource={fetchedBoards.data?.Data}
                />
              )
            }
          />
        </div>

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={fetchedBoards.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={fetchedBoards.data?.TotalNumberOfRecords}
        />
      </div>

      <GoBackButton />
    </Card>
  );
};

export default BoardsDetail;
