import {
  Button,
  Card,
  Divider,
  Empty,
  Pagination,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SortByFilter from './Components/SortByFilter';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import CardGridWrapper from '@Components/CardGridWrapper';
import { EntityFilter } from './types';
import { getAllCamRecords } from '@Store/CameraRecords/action';
import { VideoCameraOutlined, PlusOutlined } from '@ant-design/icons';
import EntityIdFilter from './Components/EntityIdFilter';
import { CameraRecordTable } from '@Types/CameraRecords';
import './style.scss';
import useCheckPermission from '@Hooks/useCheckPermission';
import { Permissions } from '@Enums/Permission';
import RecordProcessTable from './Components/RecordProcessTable';
import { convertCameraRecordEnum } from '@Utils/CameraRecords';
import { CameraRecordStatus } from '@Enums/CameraRecord';
import { LiteralUnion } from 'antd/lib/_util/type';
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import { ColumnsType } from 'antd/lib/table';
import { getCodeVersions } from '@Store/CodeVersion/action';
import ProcessNewVersionButton from './Components/ProcessNewVersionButton';
import { convertApiDate } from '@Utils/index';
import RecordProcessStatusFilter from './Components/RecordProcessStatusFilter';
import HierarchyItems from '@Components/Card/HierarchyItems';
import { RecordProcessProvider } from './Contexts';
import CameraRecordsSummary from './Components/CameraRecordsSummary';
import AssignTagModal from './Components/AssignTagModal';
import { useUserPageConfig } from '@Hooks/useUserPageConfig';
import { IsWatchedFilter } from './Components/IsWatchedFilter';
import { useSetHeaderTitle } from '@Hooks/useSetHeaderTitle';
import { usePaginatedQuery } from '@Hooks/usePaginatedQuery';
import { FilterOption } from '@Types/Pagination';
import { FilterType, PaginationFilter } from '@Components/PaginationFilter';
import { PaginationOrderByFilter } from '@Components/PaginationOrderByFilter';
import { enumToIterable } from '@Utils/helpers';
import Status from '@Enums/Status';
import { useUserSelectList } from '@Hooks/api';

const CameraRecords = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [tableData, setTableData] = useState<CameraRecordTable[]>();
  const [entityIds, setEntityIds] = useState<EntityFilter>({
    brandId: undefined,
    storeId: undefined,
    cameraId: undefined,
    recordId: undefined,
    processStatus: undefined,
    iswatch: undefined,
  });
  const cameraRecords = useAppSelector(s => s.CameraRecord.allCamRecords);

  const { t } = useTranslation();

  const allUsers = useUserSelectList();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCodeVersions());
  }, []);

  const filterOptions: FilterOption[] = [
    { field: 'Id', label: 'ID', type: FilterType.NUMBER },
    {
      field: 'Camera.Store.BrandId',
      label: t('brand') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Camera.Brand.Name', label: t('brand'), type: FilterType.TEXT },
    {
      field: 'Camera.StoreId',
      label: t('store') + ' ID',
      type: FilterType.NUMBER,
    },
    { field: 'Camera.Store.Name', label: t('store'), type: FilterType.TEXT },
    { field: 'CameraId', label: t('camera') + ' ID', type: FilterType.NUMBER },
    { field: 'Camera.Name', label: t('camera'), type: FilterType.TEXT },
    {
      field: 'CameraRecordStatus',
      label: t('externalId'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      options: enumToIterable(CameraRecordStatus).map(([_, value]) => ({
        label: convertCameraRecordEnum(value),
        value: value,
      })),
    },
    {
      field: 'CameraRecordProcesses.IsWatched',
      label: t('watched'),
      type: FilterType.RADIO,
      radioOptions: [
        { label: t('watched'), value: true },
        { label: t('notWatched'), value: false },
        { label: t('all'), value: undefined },
      ],
    },
    {
      field: 'CreatedByUserId',
      label: t('users'),
      type: FilterType.NUMBER_ARRAY_SELECT,
      loading: allUsers?.status === Status.pending,
      options: allUsers?.data?.map(u => ({
        label: u.Text,
        value: u.Value,
      })),
    },
  ];

  const pageConfigResult = useUserPageConfig(
    'cameraRecords',
    {
      orderBy: 'desc',
      sortKey: 'Id',
    },
    { fields: filterOptions.map(o => o.field) }
  );

  const { pagination, refetch } = usePaginatedQuery({
    status: cameraRecords.status,
    filterOptions,
    pageConfigResult,
    queryFunction: p =>
      dispatch(
        getAllCamRecords({
          filters: { ...p, Tags: tags },
          entityIds: mergeEntityIdsForAPI(),
        })
      ),
  });

  useSetHeaderTitle(t('cameraRecords'));

  const camRecordActionPerm = useCheckPermission(
    Permissions.ADMIN_CAMERA_RECORDS_ACTION
  );

  useEffect(() => {
    if (cameraRecords.status === 'fulfilled') {
      const data = formatCameraRecordsForTable();
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [cameraRecords.status]);

  const formatCameraRecordsForTable = (): CameraRecordTable[] => {
    const formattedData: CameraRecordTable[] = [];

    if (!Array.isArray(cameraRecords.data?.Data)) {
      return [];
    }

    for (const cameraRecord of cameraRecords.data?.Data) {
      const startDate = convertApiDate(cameraRecord.StartDate);
      const endDate = convertApiDate(cameraRecord.EndDate);
      const formattedRecord: CameraRecordTable = {
        key: cameraRecord.Id,
        'record-Status': convertCameraRecordEnum(cameraRecord.Status),
        'record-StartDate': startDate,
        'record-EndDate': endDate,
        data: cameraRecord,
      };

      formattedData.push(formattedRecord);
    }

    return formattedData;
  };

  const handleGetCameraRecordsButtonClick = () => {
    // Reset page index
    pagination.onPageIndexChange(1);

    // Fetch camera records
    refetch();
  };

  const mergeEntityIdsForAPI = (): string => {
    let mergedIds = '';

    for (const [entity, value] of Object.entries(entityIds)) {
      if (!value) continue;
      mergedIds === '' ? (mergedIds += '?') : (mergedIds += '&');
      mergedIds += `${entity}=${value}`;
    }

    return mergedIds;
  };

  const findTagColorForStatus = (
    status: CameraRecordStatus
  ): LiteralUnion<PresetColorType | PresetStatusColorType, string> => {
    switch (status) {
      case 0:
        return 'geekblue';
      case 1:
        return 'blue';
      case 6:
        return 'lime';
      case 7:
        return 'processing';
      case 8:
        return 'error';
      case 10:
        return 'success';
    }

    return 'geekblue';
  };

  const handleTagChange = (values: string[]) => setTags(values || []);

  const columns: ColumnsType<CameraRecordTable> = [
    {
      title: t('recordId'),
      dataIndex: 'record-Id',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <Tag color="orange" icon={<VideoCameraOutlined />}>
          {record.data.Id}
        </Tag>
      ),
    },
    {
      title: t('entityIds'),
      dataIndex: 'record-EntityIds',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <HierarchyItems
          brandId={record.data.BrandId}
          brand={record.data.Brand}
          storeId={record.data.StoreId}
          store={record.data.Store}
          cameraId={record.data.CameraId}
          camera={record.data.Camera}
          textWidth="15ch"
        />
      ),
    },
    {
      title: t('status'),
      dataIndex: 'record-Status',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <Tag color={findTagColorForStatus(record.data.Status)}>
          {record['record-Status']}
        </Tag>
      ),
    },
    {
      title: t('recordRange'),
      dataIndex: 'record-Duration',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <Tag color="magenta">
          {
            <Space direction="vertical" size={1}>
              {record['record-StartDate']}
              {record['record-EndDate']}
            </Space>
          }
        </Tag>
      ),
    },
    {
      title: t('processWithNewVersion'),
      dataIndex: 'record-recordProcessWithNewVersion',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <ProcessNewVersionButton record={record} getCameraRecords={refetch} />
      ),
    },
    {
      title: t('tags'),
      dataIndex: 'record-recordProcessWithNewVersion',
      align: 'center',
      render: (_: any, record: CameraRecordTable) => (
        <AssignTagModal cameraRecord={record.data} />
      ),
    },
  ];

  const recordProcessStatusFilterHandler = (status: number) => {
    setEntityIds(p => ({ ...p, processStatus: status }));
  };

  const isWatchedFilterHandler = (status: 'true' | 'false' | undefined) => {
    setEntityIds(p => ({ ...p, iswatch: status }));
  };

  return (
    <Card>
      <div className="grid-content-wrapper">
        <div className="filter-row">
          <SortByFilter
            onChange={pagination.onSortByChange}
            defaultValue={pagination.SortBy}
          />
          <PaginationOrderByFilter
            onChange={pagination.onOrderByChange}
            defaultValue={pagination.OrderBy}
          />
          <PaginationFilter
            filterOptions={filterOptions}
            pagination={pagination}
          />
          <Link to="new-camera-record">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              disabled={!camRecordActionPerm}
            >
              {t('addCameraRecord')}
            </Button>
          </Link>
        </div>

        <div className="filter-row">
          <EntityIdFilter entityIds={entityIds} setEntityIds={setEntityIds} />
          <RecordProcessStatusFilter
            onChange={recordProcessStatusFilterHandler}
            value={entityIds.processStatus}
          />
          <IsWatchedFilter
            onChange={isWatchedFilterHandler}
            value={entityIds.iswatch}
          />
          <Select
            mode="tags"
            placeholder={t('camRecTagPlaceholder')}
            onChange={handleTagChange}
            className="tag-filter"
          />
          <Button
            className="get-cam-records-btn"
            type="primary"
            onClick={handleGetCameraRecordsButtonClick}
          >
            {t('getCameraRecords')}
          </Button>
        </div>

        {cameraRecords.data.TotalStatuses && (
          <CameraRecordsSummary
            className="camera-records-summary"
            totalStatuses={cameraRecords.data.TotalStatuses}
          />
        )}

        <CardGridWrapper
          status={cameraRecords.status}
          noData={cameraRecords.data?.Data?.length === 0}
          cards={
            <Table
              columns={columns}
              dataSource={tableData}
              expandable={{
                expandedRowKeys: tableData?.map(o => o.key),
                expandedRowRender: r => (
                  <>
                    <RecordProcessProvider>
                      <RecordProcessTable
                        record={r}
                        getCameraRecords={refetch}
                      />
                    </RecordProcessProvider>
                    <Divider />
                  </>
                ),
                showExpandColumn: false,
              }}
              bordered
              size="small"
              pagination={false}
              locale={{
                emptyText: <Empty description={t('noData')} />,
              }}
            />
          }
        />

        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pagination.PageIndex}
          onChange={pagination.onPageIndexChange}
          pageSize={cameraRecords.data?.PageSize || 20}
          showSizeChanger
          onShowSizeChange={(_, s) => pagination.onPageSizeChange(s)}
          showLessItems={true}
          showTotal={total => `Total: ${total}`}
          total={cameraRecords.data?.TotalNumberOfRecords}
        />
      </div>
    </Card>
  );
};

export default CameraRecords;
