import CardGridWrapper from '@Components/CardGridWrapper';
import { BrandGroupCard } from '@Features/brand-groups/components';
import { useHomepageContext } from '@Features/homepage/hooks';
import useBoolean from '@Hooks/useBoolean';
import { getBrandGroupsWithoutPagination } from '@Store/BrandGroup/action';
import { useAppSelector } from '@Store/hooks';
import { Collapse, Input, Row, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RefreshButton } from '../RefreshButton';
import {
  BRAND_QUERY_PARAM,
  GROUP_QUERY_PARAM,
  STORE_QUERY_PARAM,
} from '@Features/homepage/homepage.constants';
import { localeLowercase } from '@Utils/helpers';

export function GroupSelector() {
  const groups = useAppSelector(s => s.BrandGroup.brandGroups);
  const brands = useAppSelector(s => s.Brand.allBrands);

  const {
    groupId,
    groupSearchValue,
    setGroupSearchValue,
    setBrandSearchValue,
    setStoreSearchValue,
    setCameraSearchValue,
    setBoardSearchValue,
    setGroupId,
    setBrandId,
    setStoreId,
    setQueryParam,
    deleteQueryParam,
  } = useHomepageContext();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    getGroups();
  }, []);

  const accordionState = useBoolean();

  function handleCollapseClick(key: string | string[]) {
    if (key === 'panel') {
      accordionState.setTrue();
    } else {
      accordionState.setFalse();
    }
  }

  function getGroups() {
    dispatch(getBrandGroupsWithoutPagination());
  }

  function searchChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setGroupSearchValue(e.target.value);
    setBrandSearchValue('');
    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
  }

  function unselectGroupHandler() {
    setGroupId(undefined);
    deleteQueryParam(GROUP_QUERY_PARAM);

    setBrandId(undefined);
    deleteQueryParam(BRAND_QUERY_PARAM);

    setStoreId(undefined);
    deleteQueryParam(STORE_QUERY_PARAM);
  }

  function groupChangeHandler(id: number) {
    setGroupId(id);
    setQueryParam(GROUP_QUERY_PARAM, id);

    setBrandId(undefined);
    deleteQueryParam(BRAND_QUERY_PARAM);

    setStoreId(undefined);
    deleteQueryParam(STORE_QUERY_PARAM);

    setGroupSearchValue('');
    setBrandSearchValue('');
    setStoreSearchValue('');
    setCameraSearchValue('');
    setBoardSearchValue('');
    accordionState.setFalse();
  }

  const filteredGroups = groups?.data?.Data?.filter(
    group =>
      brands.data?.Data?.some(brand => brand.GroupId === group.Id) && // at least 1 group id match exists with a brand's group id
      (group.Id.toString().includes(groupSearchValue) ||
        localeLowercase(group.Name).includes(localeLowercase(groupSearchValue)))
  );

  const selectedGroup = groups?.data?.Data?.find(group => groupId === group.Id);

  if (filteredGroups?.length === 0) return <></>;

  return (
    <Collapse
      accordion
      activeKey={accordionState.value ? 'panel' : undefined}
      onChange={handleCollapseClick}
      style={{ minWidth: 298 }}
    >
      <Collapse.Panel
        key="panel"
        header={
          <Row justify="space-between" align="middle" wrap>
            <Title level={5} style={{ margin: 0 }} className="selector-title">
              {t('group')}
            </Title>
            {selectedGroup && (
              <Tag
                closable
                onClose={unselectGroupHandler}
                color="cyan"
                className="selector-tag"
              >
                {selectedGroup.Name}
              </Tag>
            )}
          </Row>
        }
      >
        <div className="homepage-entity-selector-container">
          <div className="store-selector-row">
            <Input
              onChange={searchChangeHandler}
              value={groupSearchValue}
              placeholder={t('searchGroupNamePlaceholder')}
            />
            <RefreshButton
              onClick={getGroups}
              buttonProps={{ size: 'middle' }}
            />
          </div>

          <CardGridWrapper
            cards={filteredGroups?.map(group => (
              <BrandGroupCard
                key={group.Id}
                brandGroup={group}
                onRefetch={getGroups}
                onClick={() => groupChangeHandler(group.Id)}
                isSelected={selectedGroup?.Id === group.Id}
              />
            ))}
            noData={filteredGroups?.length === 0}
            status={groups.status}
            error={groups.ErrorMessage}
            containerClassName="homepage-entity-selector"
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
