import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from 'antd';
import {
  AimOutlined,
  ControlOutlined,
  HomeOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ModalFooter from '@Components/ModalFooter';
import { useForm } from 'antd/lib/form/Form';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { Board } from '@Types/Board';
import { getCodeVersions } from '@Store/CodeVersion/action';
import { getBoardDetail, resetCurrentBoard } from '@Store/Board/action';
import CardEditInfo from '@Components/CardEditInfo';
import { CardEditItemProps } from '@Components/CardEditInfo/CardEditItem';
import { Permissions } from '@Enums/Permission';
import useCheckPermission from '@Hooks/useCheckPermission';
import SearchableSelect from '@Components/SearchableSelect';
import { getAllHardware, getBoardBrandCameras } from '@Store/Hardware/action';
import { Popconfirm } from '@Components/Popconfirm';
import BoardsService from '@Services/Api/Boards';
import { apiCall, convertApiDate, interFaceCameraTypes } from '@Utils/index';
import './style.scss';
import { useNavigate, useParams } from 'react-router-dom';
import useBoolean from '@Hooks/useBoolean';
import { InterfaceCameraTypeEnums } from '@Enums/Camera';
import ContentLoader from '@Components/ContentLoader';
import {
  BoardAdapterBrand,
  BoardColor,
  BoardRaySpotType,
  InstallationStatus,
} from '@Enums/Board';
import { enumToIterable } from '@Utils/helpers';
import { noPaginationParams } from '@Hooks/usePagination.ts/constants';
import ReceiverService from '@Services/Api/Receivers';
import { EditedReceiver } from '@Types/Receiver';

type ReceiverForm = {
  ReceiverShrinkDataFromLineEntrance: boolean;
};

type Props = {
  board?: Board;
  onFinish: (values: any) => void;
};

/*
    This form is used for editing and adding new board.
    Some of the form elements are rendered for ONLY editing or ONLY adding page.
    This behavior is achieved by checking the board prop (board prop will be obviously not passed for the new board page).
*/
export default function BoardForm({ onFinish, board }: Props) {
  const [infoItems, setInfoItems] = useState<CardEditItemProps[]>([]);
  const codeVersions = useAppSelector(s => s.CodeVersion.codeVersions.data);

  const allHardware = useAppSelector(s => s.Hardware.allHardware);
  const boardBrandCameras = useAppSelector(s => s.Hardware.boardBrandCameras);

  useEffect(() => {
    dispatch(getCodeVersions());
    getHardwares();
    dispatch(getBoardBrandCameras());

    return () => {
      dispatch(resetCurrentBoard());
    };
  }, []);

  const getHardwares = () => {
    dispatch(getAllHardware(noPaginationParams));
  };

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [form] = useForm();
  const [receiverForm] = useForm<ReceiverForm>();
  let navigate = useNavigate();

  const brandPerm = useCheckPermission(Permissions.ADMIN_BRAND_VIEW);
  const storePerm = useCheckPermission(Permissions.ADMIN_STORE_VIEW);
  const BoardBrandModelId = Form.useWatch('BoardBrandModelId', form);
  const CreateCamera = Form.useWatch('CreateCamera', form);

  useEffect(() => {
    setInfoItems([
      {
        icon: <HomeOutlined />,
        name: board?.Brand!,
        id: board?.BrandId!,
        routeTo: `/brands/${board?.BrandId}/stores`,
        hasPermission: brandPerm,
      },
      {
        icon: <ShopOutlined />,
        name: board?.Store!,
        id: board?.StoreId!,
        routeTo: `/brands/${board?.BrandId}/stores/${board?.StoreId}/boards`,
        hasPermission: storePerm,
      },
    ]);
    form.resetFields();
  }, [board?.Id]);

  function handleSubmit(values: any) {
    if (!values?.CreateCamera) {
      if (values?.CameraType !== undefined) {
        delete values['CameraType'];
      }

      if (values?.RTSPUrl !== undefined) {
        delete values['RTSPUrl'];
      }
    }

    onFinish(values);
  }

  async function handleReceiverFormSubmit(values: ReceiverForm) {
    await apiCall(
      () =>
        editReceiver({
          ShrinkDataFromLineEntrance: values.ReceiverShrinkDataFromLineEntrance
            ? 1
            : 0,
        }),
      'editReceiver'
    );
    navigate(-1);
  }

  const editReceiver = async (newReceiver: EditedReceiver) => {
    if (!board?.ReceiverId) return;

    return await new ReceiverService().EditReceiver(
      board.ReceiverId,
      newReceiver
    );
  };

  function handleCameraTypeChange(value: InterfaceCameraTypeEnums) {
    const boardBrandCamera = boardBrandCameras?.data?.find(
      b =>
        b.CameraInterfaceType === value &&
        b.BoardBrandModelId === BoardBrandModelId
    );

    if (boardBrandCamera) {
      form.setFieldsValue({ RTSPUrl: boardBrandCamera.DefaultURL });
    }
  }

  function handleHardwareChange() {
    form.setFieldsValue({
      RTSPUrl: undefined,
      InterfaceType: undefined,
      CodeVersionId: undefined,
    });
  }

  const inputRule = [{ required: true, message: t('cannotBeEmpty') }];

  const initialValues = {
    CardName: board?.Name,
    CodeVersionId: board?.VersionId,
    IsHasWifiDongle: board?.IsHasWifiDongle,
    IsHasBluetoothDongle: board?.IsHasBluetoothDongle,
    IsHasPoeSplitter: board?.IsHasPoeSplitter,
    IsHasRaySpot: board?.IsHasRaySpot,
    IsHasHelperBoard: board?.IsHasHelperBoard,
    InstallationStatus: board?.InstallationStatus,
    BoardBrandModelId: board?.BoardBrandModelId,
    Color: board?.Color,
    AdapterBrand: board?.AdapterBrand,
    RaySpotType: board?.RaySpotType,
    ReceiverShrinkDataFromLineEntrance:
      board?.ReceiverShrinkDataFromLineEntrance,
  };

  const cameraTypeOptions = interFaceCameraTypes.map(cameraType => ({
    label: cameraType.type,
    value: cameraType.value,
  }));

  const boardColorOptions = enumToIterable(BoardColor).map(
    ([label, value]) => ({
      label: t(`BoardColors.${label}`),
      value,
    })
  );

  const boardRaySpotTypeOptions = enumToIterable(BoardRaySpotType).map(
    ([label, value]) => ({
      label: t(`BoardRaySpotTypes.${label}`),
      value,
    })
  );

  const boardAdapterBrandOptions = enumToIterable(BoardAdapterBrand).map(
    ([label, value]) => ({
      label: t(`BoardAdapterBrands.${label}`),
      value,
    })
  );

  return (
    <ContentLoader
      status={boardBrandCameras.status}
      errorMessage={boardBrandCameras.ErrorMessage}
    >
      <>
        <Form
          form={form}
          name="board-form"
          onFinish={handleSubmit}
          className="board-detail-form-container"
          initialValues={board ? initialValues : undefined}
          labelCol={{ span: 6 }}
          labelWrap
          labelAlign="left"
          wrapperCol={{ span: 24 }}
        >
          {board && <CardEditInfo items={infoItems} />}

          <Form.Item label={t('board')} name="CardName" rules={inputRule}>
            <Input prefix={<AimOutlined />} />
          </Form.Item>

          <Form.Item
            label={t('BoardBrand')}
            name="BoardBrandModelId"
            rules={inputRule}
          >
            <SearchableSelect
              placeholder={t('Select')}
              defaultValue={board?.BoardBrandModelId}
              onChange={handleHardwareChange}
            >
              {allHardware &&
                allHardware?.data?.Data?.map(hardWare => (
                  <Select.Option
                    key={hardWare.Id}
                    value={hardWare.Id}
                    label={hardWare.Name}
                  >
                    {hardWare.Name}
                  </Select.Option>
                ))}
            </SearchableSelect>
          </Form.Item>

          <Form.Item
            label={t('codeVersion')}
            name="CodeVersionId"
            rules={inputRule}
          >
            <SearchableSelect
              placeholder={t('selectCodeVersion')}
              suffixIcon={<ControlOutlined />}
              defaultValue={board?.VersionId}
            >
              {codeVersions
                .filter(
                  codeVersion => codeVersion.HardwareId === BoardBrandModelId
                )
                .map(codeVersion => (
                  <Select.Option
                    key={codeVersion.Id}
                    value={codeVersion.Id}
                    label={`${codeVersion.Name} (${codeVersion.Version})`}
                  >
                    {`${codeVersion.Name} (${codeVersion.Version})`}
                  </Select.Option>
                ))}
            </SearchableSelect>
          </Form.Item>

          {board && (
            <Form.Item
              label={t('installationStatus')}
              name="InstallationStatus"
            >
              <Radio.Group>
                <Radio value={InstallationStatus.Installed}>
                  {t('hadInstalled')}
                </Radio>
                <Radio value={InstallationStatus.NotInstalled}>
                  {t('notHadInstalled')}
                </Radio>
                <Radio value={InstallationStatus.WaitingCustomer}>
                  {t('waitingCustomer')}
                </Radio>
                <Radio value={InstallationStatus.OnTheWay}>
                  {t('onTheWay')}
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item label={t('color')} name="Color" rules={inputRule}>
            <Select options={boardColorOptions} />
          </Form.Item>

          <Form.Item label={t('raySpotType')} name="RaySpotType">
            <Select allowClear options={boardRaySpotTypeOptions} />
          </Form.Item>

          <Form.Item label={t('adapterBrand')} name="AdapterBrand">
            <Select allowClear options={boardAdapterBrandOptions} />
          </Form.Item>

          {!board && (
            <Form.Item label={t('countForNewBoard')} name="Count">
              <Input type="number" min={0} max={25} />
            </Form.Item>
          )}

          {!board && (
            <Form.Item
              label={t('createCamera')}
              name="CreateCamera"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          )}

          {!board && CreateCamera && (
            <>
              <Form.Item
                label={t('cameraType')}
                name="InterfaceType"
                rules={inputRule}
              >
                <Select
                  options={cameraTypeOptions}
                  onChange={handleCameraTypeChange}
                />
              </Form.Item>
              <Form.Item label="RTSP Url" name="RTSPUrl">
                <Input />
              </Form.Item>
            </>
          )}

          <p>{t('additionalDevices')} :</p>
          <Row>
            <Col span={6}>
              <Form.Item name="IsHasWifiDongle" valuePropName="checked">
                <Checkbox> Wifi Dongle</Checkbox>
              </Form.Item>
            </Col>{' '}
            <Col span={6}>
              <Form.Item name="IsHasBluetoothDongle" valuePropName="checked">
                <Checkbox> Bluetooth Dongle</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="IsHasPoeSplitter" valuePropName="checked">
                <Checkbox> Poe Splitter</Checkbox>
              </Form.Item>
            </Col>{' '}
            <Col span={6}>
              <Form.Item name="IsHasHelperBoard" valuePropName="checked">
                <Checkbox>Helper Board</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <ModalFooter formKey="board-form" hideGoBackButton />

        {board && (
          <>
            <Divider />
            {board.ReceiverSet ? (
              <Form
                form={receiverForm}
                name="receiver-form"
                onFinish={handleReceiverFormSubmit}
                initialValues={
                  board
                    ? {
                        ReceiverShrinkDataFromLineEntrance:
                          board.ReceiverShrinkDataFromLineEntrance,
                      }
                    : undefined
                }
                labelCol={{ span: 6 }}
                labelWrap
                labelAlign="left"
                wrapperCol={{ span: 24 }}
              >
                <Form.Item
                  name="ReceiverShrinkDataFromLineEntrance"
                  label={t('shrinkDataFromLineEntranceShort')}
                  tooltip={t('shrinkDataFromLineEntrance')}
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item label={t('receiverSet')}>
                  {convertApiDate(board.ReceiverSet)}
                </Form.Item>
                <CancelTagAsReceiverButton board={board} />

                <ModalFooter formKey="receiver-form" hideGoBackButton />
              </Form>
            ) : (
              <TagAsReceiverButton board={board} />
            )}
          </>
        )}

        <ModalFooter formKey="board-form" hideSendButton />
      </>
    </ContentLoader>
  );
}

/**
 * @link https://skytuna.s-ul.eu/JzMG3x09
 */
function TagAsReceiverButton({ board }: { board: Board }) {
  const codeVersionModal = useBoolean();
  const dongleModal = useBoolean();

  const { boardId } = useParams();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  async function sendToApi() {
    await apiCall(() => new BoardsService().TagAsReceiver(board.Id));

    if (boardId) {
      dispatch(getBoardDetail(parseInt(boardId)));
    }
  }

  function handleCodeVersionWarningConfirm() {
    codeVersionModal.setFalse();

    if (board.IsHasBluetoothDongle) {
      sendToApi();
      return;
    }

    dongleModal.setTrue();
  }

  function handleDongleWarningConfirm() {
    dongleModal.setFalse();

    sendToApi();
  }

  function handleCodeVersionWarningChange(open: boolean) {
    if (!open) {
      codeVersionModal.setFalse();
    }
  }

  function handleDongleWarningChange(open: boolean) {
    if (!open) {
      dongleModal.setFalse();
    }
  }

  return (
    <Popconfirm
      open={codeVersionModal.value}
      onConfirm={handleCodeVersionWarningConfirm}
      onCancel={codeVersionModal.setFalse}
      onOpenChange={handleCodeVersionWarningChange}
      title={t('tagAsReceiverCodeVersionWarning')}
      overlayStyle={{ maxWidth: '600px' }}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('okay')}
    >
      <Popconfirm
        open={dongleModal.value}
        onConfirm={handleDongleWarningConfirm}
        onCancel={dongleModal.setFalse}
        onOpenChange={handleDongleWarningChange}
        title={t('tagAsReceiverNoDonglePopconfirm')}
        disabled={board.IsHasBluetoothDongle}
        overlayStyle={{ maxWidth: '600px' }}
      >
        <Button
          className="tag-receiver-button"
          icon={<AimOutlined />}
          onClick={codeVersionModal.setTrue}
        >
          {t('tagAsReceiverButton')}
        </Button>
      </Popconfirm>
    </Popconfirm>
  );
}

function CancelTagAsReceiverButton({ board }: { board: Board }) {
  const { boardId } = useParams();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const cancelTagAsReceiver = () =>
    new BoardsService().RemoveTagAsReceiver(board.Id);

  async function handleConfirmClick() {
    await apiCall(cancelTagAsReceiver);

    if (boardId) {
      dispatch(getBoardDetail(parseInt(boardId)));
    }
  }

  return (
    <Popconfirm
      title={t('untagAsReceiverPopconfirm')}
      onConfirm={handleConfirmClick}
    >
      <Button className="tag-receiver-button" danger icon={<AimOutlined />}>
        {t('untagAsReceiverButton')}
      </Button>
    </Popconfirm>
  );
}
