import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Segmented,
  Space,
  Typography,
} from 'antd';
import {
  BarsOutlined,
  EnvironmentOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@Store/hooks';
import { logout as reduxLogout } from '../../Store/Account/action';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { MINUTES_IN_HOUR } from '@Utils/index';
import { IUserConfig } from '@Types/User';
import UserService from '@Services/Api/User';
import config from '~../../../config';
import {
  onSidebarHiddenToggle,
  setHomepageView,
} from '@Store/Dashboard/action';
import { HomepageView } from '@Types/Dashboard';
import { setUserDetails } from '@Store/User/action';

const { Title } = Typography;
const AntHeader = Layout.Header;

function Header() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const location = useLocation();

  const headerTitle = useAppSelector(s => s.Dashboard.headerTitle);
  const homepageView = useAppSelector(s => s.Dashboard.homepageView);
  const isSidebarHidden = useAppSelector(
    state => state.Dashboard.isSidebarHidden
  );

  const userConfig = useAppSelector(
    s => s.User.currentUserDetails.data.PanelConfig
  );

  useEffect(() => {
    const parsedConfig = userConfig
      ? (JSON.parse(userConfig) as IUserConfig)
      : null;

    const language = parsedConfig?.adminPanel?.language || i18n.language;
    i18n.changeLanguage(language);
    setMomentLang(language);
  }, []);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setMomentLang(lang);
    setUserLanguageConfig(lang);
  };

  const setMomentLang = (lang: string) => {
    switch (lang) {
      case 'tr-TR':
        moment.locale('tr');
        break;
      case 'en-US':
        moment.locale('en');
        break;
      default:
        break;
    }
  };

  const handleLogout = (): void => {
    dispatch(reduxLogout());
    navigate('/');
  };

  const setUserLanguageConfig = async (language: string) => {
    const parsedConfig = userConfig
      ? (JSON.parse(userConfig) as IUserConfig)
      : null;

    const newUserConfig = {
      ...parsedConfig,
      adminPanel: {
        ...parsedConfig?.adminPanel,
        language,
      },
    } as IUserConfig;

    await new UserService().SetPanelConfig(JSON.stringify(newUserConfig));
    const user = await new UserService().GetDetailsV2();
    dispatch(setUserDetails(user));
  };

  function handleHomepageChange(value: string | number) {
    dispatch(setHomepageView(value as HomepageView));
  }

  const UserActionDropdown = () => (
    <Menu>
      <Menu.Item key="0">
        <Title level={5}>{t('language')}</Title>
        <Space>
          <div
            className={`${i18n.language === 'en-US' ? 'active' : ''} language`}
            onClick={() => changeLanguage('en-US')}
          >
            <Avatar size="small" src={'assets/languages/icon__flag-en.svg'} />
          </div>
          <div
            className={`${i18n.language === 'tr-TR' ? 'active' : ''} language`}
            onClick={() => changeLanguage('tr-TR')}
          >
            <Avatar size="small" src={'assets/languages/icon__flag-tr.svg'} />
          </div>
        </Space>
      </Menu.Item>
      <Menu.Item key="1">
        <Typography.Text>v{config.version}</Typography.Text>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={handleLogout}>
        <Space>
          <LogoutOutlined />
          {t('logout')}
        </Space>
      </Menu.Item>
    </Menu>
  );

  const timezoneOffset = useMemo(() => {
    const offset = -new Date().getTimezoneOffset() / MINUTES_IN_HOUR;
    return `GMT ${offset > 0 ? '+' : '-'}${Math.abs(offset)}`;
  }, []);

  const homepageViewOptions = useMemo(
    () => [
      {
        label: t('listView'),
        value: 'list',
        icon: <BarsOutlined />,
      },
      {
        label: t('mapView'),
        value: 'map',
        icon: <EnvironmentOutlined />,
      },
    ],
    [t]
  );

  const isHomepage = location.pathname === '/';

  return (
    <AntHeader
      className={`app-header ${config.isTestEnv && 'App-header-test'}`}
    >
      <Button
        className="sidebar-button"
        icon={<MenuOutlined style={{ color: '#fff' }} />}
        type="text"
        onClick={() => dispatch(onSidebarHiddenToggle(!isSidebarHidden))}
      />

      <Title className="header-title">{headerTitle}</Title>

      <Space
        direction="horizontal"
        size="large"
        className="right-side-container"
      >
        {isHomepage && (
          <Segmented
            value={homepageView}
            className="homepage-view-selector"
            onResize={undefined}
            onResizeCapture={undefined}
            options={homepageViewOptions}
            onChange={handleHomepageChange}
          />
        )}

        <Typography.Text className="timezone-text">
          {timezoneOffset}
        </Typography.Text>

        <Dropdown
          overlay={UserActionDropdown}
          trigger={['click']}
          className="user-actions"
        >
          <span onClick={e => e.preventDefault()}>
            <Space>
              <Avatar>U</Avatar>
            </Space>
          </span>
        </Dropdown>
      </Space>
    </AntHeader>
  );
}

export default Header;
