import { FilterOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Tag,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { uuidv4 } from '@Utils/helpers';
import moment from 'moment';
import {
  FilterItem,
  FilterOption,
  UsePaginationResult,
} from '@Types/Pagination';
import SearchableSelect from '@Components/SearchableSelect';
import { Tooltip } from '@Components/Tooltip';
import { usePatternError } from '@Hooks/usePatternError';

export enum FilterType {
  DATE,
  RADIO,
  TEXT,
  TEXT_SELECT,
  TEXT_ARRAY,
  TEXT_ARRAY_SELECT,
  NUMBER,
  NUMBER_SELECT,
  NUMBER_ARRAY,
  NUMBER_ARRAY_SELECT,
}

interface PaginationFilterProps {
  filterOptions: FilterOption[];
  pagination: UsePaginationResult;
}

export function PaginationFilter(props: PaginationFilterProps) {
  const { filterOptions, pagination } = props;

  const { t } = useTranslation();

  const { getErrorMessageOfPattern } = usePatternError();

  const [filters, setFilters] = useState<FilterItem[]>(
    pagination.FilterBy.map(o => ({
      id: uuidv4(),
      ...o,
      ...filterOptions.find(f => f.field === o.field),
      error: getErrorMessageOfPattern(
        filterOptions.find(f => f.field === o.field)?.pattern,
        o.value,
        o.field
      ),
    }))
  );

  function handleAddFilter() {
    setFilters(prevFilters => [...prevFilters, { id: uuidv4() }]);
  }

  function handleFilterFieldChange(id: string) {
    return (value: string) => {
      setFilters(prevFilters =>
        prevFilters.map(prevFilter => {
          const filterOption = filterOptions.find(
            filterOption => filterOption.field === value
          );

          if (prevFilter.id === id && filterOption) {
            return {
              id,
              type: filterOption.type,
              field: filterOption.field,
              label: filterOption.label,
              options:
                'options' in filterOption ? filterOption.options : undefined,
              pattern: filterOption.pattern,
            };
          }

          return prevFilter;
        })
      );
    };
  }

  function handleDeleteFilter(id: string) {
    return () => {
      setFilters(prevFilters => {
        const newFilters = prevFilters.filter(
          prevFilter => prevFilter.id !== id
        );

        pagination.onFilterByChange(
          newFilters.map(f => ({ field: f.field, value: f.value }))
        );

        return newFilters;
      });
    };
  }

  function handleFilterChange(filter: FilterItem) {
    return (value: FilterItem['value'], error?: string) => {
      setFilters(prevFilters => {
        const newFilters = prevFilters.map(prevFilter =>
          prevFilter.id === filter.id
            ? { ...prevFilter, value, error }
            : prevFilter
        );

        pagination.onFilterByChange(
          newFilters.map(f => ({ field: f.field, value: f.value }))
        );

        return newFilters;
      });
    };
  }

  const getFormField = (
    filter: FilterItem,
    onChange: (value: FilterItem['value'] | undefined, error?: string) => void
  ) => {
    const { value, type, field } = filter;

    const selectPlaceholder = t('selectXFilter', { value: filter.label });
    const inputPlaceholder = t('inputXFilter', { value: filter.label });

    const filterOption = filterOptions.find(f => f.field === field);

    switch (type) {
      case FilterType.DATE:
        return (
          <DatePicker
            value={moment(value as string)}
            onChange={v => onChange(v ?? undefined)}
            format="DD/MM/YYYY"
            size="small"
            placeholder={selectPlaceholder}
          />
        );

      case FilterType.RADIO:
        return (
          <Radio.Group
            style={{ marginLeft: 4 }}
            value={value}
            onChange={e => onChange(e.target.value)}
          >
            {filterOption?.radioOptions?.map(o => (
              <Radio key={o.label} value={o.value}>
                {o.label}
              </Radio>
            ))}
          </Radio.Group>
        );

      case FilterType.TEXT:
        if (!filter.pattern) {
          return (
            <Input
              size="small"
              value={value as string}
              onChange={e => onChange(e.target.value)}
              placeholder={inputPlaceholder}
              allowClear
            />
          );
        }

        return (
          <Form.Item
            validateStatus={filter.error ? 'error' : 'success'}
            className="filter-text-form-item"
          >
            <div className="filter-text-item">
              <Input
                size="small"
                value={value as string}
                onChange={e => {
                  onChange(
                    e.target.value,
                    getErrorMessageOfPattern(
                      filter.pattern,
                      e.target.value,
                      filter.field
                    )
                  );
                }}
                placeholder={new RegExp(filter.pattern).toString()}
                title={new RegExp(filter.pattern).toString()}
                allowClear
              />
              {filter.error && (
                <Typography.Text className="filter-pattern-error" type="danger">
                  {filter.error}
                </Typography.Text>
              )}
            </div>
          </Form.Item>
        );

      case FilterType.TEXT_SELECT:
        return (
          <SearchableSelect
            value={value}
            onChange={v => onChange(v)}
            dropdownMatchSelectWidth={false}
            options={filterOption?.options}
            size="small"
            maxTagCount={1}
            placeholder={selectPlaceholder}
            allowClear
            loading={filterOption?.loading}
          />
        );

      case FilterType.TEXT_ARRAY:
        return (
          <SearchableSelect
            value={value}
            onChange={v => onChange(v)}
            dropdownMatchSelectWidth={false}
            mode="tags"
            size="small"
            maxTagCount={1}
            placeholder={inputPlaceholder}
            allowClear
          />
        );

      case FilterType.TEXT_ARRAY_SELECT:
        return (
          <SearchableSelect
            value={value}
            onChange={v => onChange(v)}
            dropdownMatchSelectWidth={false}
            options={filterOption?.options}
            mode="multiple"
            size="small"
            maxTagCount={1}
            placeholder={selectPlaceholder}
            allowClear
            loading={filterOption?.loading}
          />
        );

      case FilterType.NUMBER:
        return (
          <InputNumber
            className="number-filter"
            size="small"
            value={value as number}
            onChange={v => onChange(v as number)}
            style={{ width: 'auto' }}
            placeholder={inputPlaceholder}
          />
        );

      case FilterType.NUMBER_SELECT:
        return (
          <SearchableSelect
            value={value}
            onChange={v => onChange(v)}
            dropdownMatchSelectWidth={false}
            options={filterOption?.options}
            size="small"
            maxTagCount={1}
            placeholder={selectPlaceholder}
            allowClear
            loading={filterOption?.loading}
          />
        );

      case FilterType.NUMBER_ARRAY:
        return (
          <SearchableSelect
            value={value}
            onChange={v => onChange(v)}
            dropdownMatchSelectWidth={false}
            mode="tags"
            size="small"
            maxTagCount={1}
            placeholder={inputPlaceholder}
            allowClear
          />
        );

      case FilterType.NUMBER_ARRAY_SELECT:
        return (
          <SearchableSelect
            value={value}
            onChange={v => onChange(v)}
            dropdownMatchSelectWidth={false}
            options={filterOption?.options}
            mode="multiple"
            size="small"
            maxTagCount={1}
            placeholder={selectPlaceholder}
            allowClear
            loading={filterOption?.loading}
          />
        );
    }
  };

  const availableFilterOptions = filterOptions.filter(
    filterOption => !filters.some(filter => filter.field === filterOption.field)
  );

  return (
    <div className="pagination-filter">
      {filters.map(filter => (
        <Tag key={filter.id} closable onClose={handleDeleteFilter(filter.id)}>
          <SearchableSelect
            value={filter.label}
            size="small"
            onChange={handleFilterFieldChange(filter.id)}
            options={availableFilterOptions.map(filterOption => ({
              label: filterOption.label,
              value: filterOption.field,
            }))}
            placeholder={t('selectFilter')}
            disabled={availableFilterOptions.length === 0}
            dropdownMatchSelectWidth={false}
          />

          <div className="form-field-container">
            {filter.type !== undefined &&
              getFormField(filter, handleFilterChange(filter))}
          </div>
        </Tag>
      ))}

      <Tooltip title={t('clickToAddFilter')}>
        <Button
          onClick={handleAddFilter}
          icon={<FilterOutlined />}
          disabled={
            filters.length > 0 &&
            (!filters[filters.length - 1]?.field ||
              availableFilterOptions.length === 0)
          }
        />
      </Tooltip>
    </div>
  );
}
